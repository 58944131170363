import styled from 'styled-components';
import Culori from '../../constante/Culori';

export const ContainerButon = styled.button`
  width: 50px;
  height: 50px;
  background-color: ${Culori.alb};
  border-radius: 50%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border: none;
  outline: none;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 834px) {
    border: none;
    box-shadow: none;
    outline: none;

    &:hover {
      box-shadow: none;
    }

    &:active,
    &:focus {
      outline: none;
      webkit-tap-highlight-color: transparent;
    }
  }
`;

export const IconitaButon = styled.img`
  width: 25px;
  height: 25px;

  webkit-tap-highlight-color: transparent;
  user-select: none;

  @media (max-width: 834px) {
    outline: none;

    &:active,
    &:focus {
      outline: none;
      // webkit-tap-highlight-color: transparent;
    }
  }
`;
