import styled from 'styled-components';

export const ContainerCarduriFacultati = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 70px);
  gap: 16px;
  padding: 0 0 16px 0;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transform: ${({ activateTransition }) =>
    activateTransition ? 'translateX(-110%)' : 'translateX(0%)'};
  transition: all 0.3s ease-in-out;

  @media (max-width: 834px) {
    margin: 0 22px 64px 22px;
    padding: 88px 0 30px 0;
  }
`;
