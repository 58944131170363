import {
  GET_SUBGRUPE_REQUEST,
  GET_SUBGRUPE_SUCCESS,
  GET_SUBGRUPE_FAILURE,
} from '../actions/subgrupeActions';

const initialState = {
  loading: false,
  subgrupe: [],
  error: null,
};

function subgrupeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBGRUPE_REQUEST:
      return { ...state, loading: true };
    case GET_SUBGRUPE_SUCCESS:
      return {
        ...state,
        loading: false,
        subgrupe: action.subgrupe,
      };
    case GET_SUBGRUPE_FAILURE:
      return { ...state, loading: false, error: action.error.message };
    default:
      return { ...state };
  }
}

export default subgrupeReducer;
