import styled from "styled-components";
import Culori from "../../constante/Culori";

export const SpinnerContainer = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid ${Culori.albastru};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.5s linear infinite;
  margin-top: 50px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
