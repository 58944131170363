import {
  GET_CADRE_DIDACTICE_REQUEST,
  GET_CADRE_DIDACTICE_SUCCESS,
  GET_CADRE_DIDACTICE_FAILURE,
} from '../actions/cadreDidacticeActions';

const initialState = {
  loading: false,
  cadreDidactice: [],
  error: null,
};

function cadreDidacticeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CADRE_DIDACTICE_REQUEST:
      return { ...state, loading: true };
    case GET_CADRE_DIDACTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        cadreDidactice: action.cadreDidactice,
      };
    case GET_CADRE_DIDACTICE_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

export default cadreDidacticeReducer;
