import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  CardArrow,
  CardContainer,
  CardIcon,
  CardIconBackground,
  CardText,
  DeleteButton,
  DeleteButtonIcon,
} from './InfoCard.styles';
import ArrowIcon from '../../../resurse/iconiteInterfata/arrow.svg';
import { useLocation } from 'react-router-dom';
import TrashIcon from '../../../resurse/iconiteInterfata/trash-icon.svg';

function InfoCard({ id, icon, text, onClick, setRegetData }) {
  const [deltaX, setDeltaX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const location = useLocation();

  const isOrareSalvate = location.pathname.includes('/orare-salvate');

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      setIsSwiping(true);
      let swipeDistance = eventData.deltaX;
      if (!isLocked) {
        setDeltaX(Math.max(-50, swipeDistance));
        setShowDeleteButton(swipeDistance < 0);
      }
    },
    onSwipedLeft: () => {
      if (!isLocked) {
        if (deltaX <= -50) {
          setIsLocked(true);
          setShowDeleteButton(true);
        }
      } else {
        setShowDeleteButton(true);
      }
      setIsSwiping(false);
    },
    onSwipedRight: () => {
      if (isLocked) {
        setIsLocked(false);
        setShowDeleteButton(false);
      }
      setIsSwiping(false);
      setDeltaX(0);
    },
    trackMouse: true,
  });

  const handleDelete = () => {
    localStorage.setItem(
      'orareSalvate',
      JSON.stringify(
        JSON.parse(localStorage.getItem('orareSalvate')).filter((orar) => orar.id !== id),
      ),
    );
    setRegetData((prev) => !prev);
    setDeltaX(0);
    setIsLocked(false);
  };

  return (
    <div
      {...(isOrareSalvate ? handlers : {})}
      style={{
        position: 'relative',
        userSelect: 'none',
        overflow: 'hidden',
        width: '100%',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: '10px',
      }}
    >
      <CardContainer
        onClick={onClick}
        style={{
          transform: `translateX(${isLocked ? '-50px' : `${deltaX}px`})`,
          transition: isSwiping ? 'none' : 'transform 0.3s ease-out',
          borderTopRightRadius: isLocked ? '0' : '10px',
          borderBottomRightRadius: isLocked ? '0' : '10px',
        }}
      >
        <CardIconBackground>
          <CardIcon src={icon} />
        </CardIconBackground>
        <CardText>{text}</CardText>
        <CardArrow src={ArrowIcon} alt="arrow" />
      </CardContainer>

      <DeleteButton
        onClick={() => {
          handleDelete();
        }}
        showDeleteButton={showDeleteButton}
        style={{
          width: `${Math.min(50, Math.abs(deltaX))}px`,
          transition: 'width 0.1s ease-out',
        }}
      >
        <DeleteButtonIcon src={TrashIcon} alt="delete" />
      </DeleteButton>
    </div>
  );
}

export default InfoCard;
