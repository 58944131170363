export const GET_SUBGRUPE_REQUEST = 'GET_SUBGRUPE_REQUEST';
export const GET_SUBGRUPE_SUCCESS = 'GET_SUBGRUPE_SUCCESS';
export const GET_SUBGRUPE_FAILURE = 'GET_SUBGRUPE_FAILURE';

export function getSubgrupeRequest() {
  return { type: GET_SUBGRUPE_REQUEST };
}

export function getSubgrupeSuccess(subgrupe) {
  return { type: GET_SUBGRUPE_SUCCESS, subgrupe };
}

export function getSubgrupeFailure(error) {
  return { type: GET_SUBGRUPE_FAILURE, error };
}

export function getSubgrupe() {
  return async (dispatch) => {
    dispatch(getSubgrupeRequest());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subgrupe.php?json`);
      const data = await response.json();
      dispatch(getSubgrupeSuccess(data));
    } catch (error) {
      console.warn('getSubgrupe error', error.message);
      dispatch(getSubgrupeFailure(error.message));
    }
  };
}
