import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ContainerCarduriFacultati } from './MobileFacultyPage.Styles';
import FIESC from '../../resurse/iconiteFacultati/iconitaFiesc.svg';
import FS from '../../resurse/iconiteFacultati/iconitaFs.svg';
import FEFS from '../../resurse/iconiteFacultati/iconitaFefs.svg';
import FSE from '../../resurse/iconiteFacultati/iconitaFse.svg';
import FDSA from '../../resurse/iconiteFacultati/iconitaFdsa.svg';
import FIMAR from '../../resurse/iconiteFacultati/iconitaFimar.svg';
import FEAA from '../../resurse/iconiteFacultati/iconitaFeaa.svg';
import Depart from '../../resurse/iconiteFacultati/iconitaDspp.svg';
import FMSB from '../../resurse/iconiteFacultati/iconitaFmsb.svg';
import FIG from '../../resurse/iconiteFacultati/iconitaFig.svg';
import FLSC from '../../resurse/iconiteFacultati/iconitaFlsc.svg';
import FIA from '../../resurse/iconiteFacultati/iconitaFia.svg';
import CSUD from '../../resurse/iconiteFacultati/iconitaCSUD.svg';
import CardFacultate from '../../componente/cardFacultate/CardFacultate';
import Antet from '../../componente/antet/Antet';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../componente/spinner/Spinner';
import { GlobalContainer } from '../paginaAcasa/PaginaAcasa.styles';
import useWindowSize from '../../utils/useWindowSize';

function MobileFacultyPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [activateTransition, setActivateTransition] = useState(false);

  useEffect(() => {
    if (width > 834) {
      navigate('/');
    }
  }, [width, navigate]);

  const { facultati } = useSelector((state) => state.facultati);
  const [isContentHidden, setIsContentHidden] = useState(true);

  const facultatiObject = {
    FIESC: { iconita: FIESC, nume: t('FIESC') },
    FS: { iconita: FS, nume: t('FS') },
    FEFS: { iconita: FEFS, nume: t('FEFS') },
    FSE: { iconita: FSE, nume: t('FSE') },
    FDSA: { iconita: FDSA, nume: t('FDSA') },
    FIMAR: { iconita: FIMAR, nume: t('FIMAR') },
    FEAA: { iconita: FEAA, nume: t('FEAA') },
    Depart: { iconita: Depart, nume: t('Depart') },
    FMSB: { iconita: FMSB, nume: t('FMSB') },
    FIG: { iconita: FIG, nume: t('FIG') },
    FLSC: { iconita: FLSC, nume: t('FLSC') },
    FIA: { iconita: FIA, nume: t('FIA') },
    CSUD: { iconita: CSUD, nume: t('CSUD') },
  };

  const AutoredimensionareElemente = () => {
    const elementeCard = document.getElementsByClassName('card');
    const elementeSwitch = document.getElementsByClassName('switch');

    const latimeCard = elementeCard[0]?.offsetWidth;
    for (let i = elementeCard.length - 3; i < elementeCard.length; i++) {
      elementeCard[i] && (elementeCard[i].style.maxWidth = `${latimeCard}px`);
    }
    for (let i = 0; i < elementeSwitch.length; i++) {
      elementeCard[i] && (elementeSwitch[i].style.maxWidth = `${latimeCard}px`);
      elementeCard[i] && (elementeSwitch[i].style.width = `${latimeCard}px`);
    }
  };

  window.addEventListener('resize', () => {
    AutoredimensionareElemente();
  });

  useEffect(() => {
    AutoredimensionareElemente();
  }, [facultati]);

  useEffect(() => {
    if (facultati.length) {
      setIsContentHidden(false);
    }
  }, [facultati]);

  return (
    <>
      <Antet showArrow title={'Facultăți'} />
      <GlobalContainer>
        <ContainerCarduriFacultati activateTransition={activateTransition} hidden={isContentHidden}>
          {facultati.length &&
            facultati.slice(1).map((facultate, index) => {
              const facultateObject = facultatiObject[facultate.shortName.replace('.', '')] || {
                name: facultate.shortName || '',
                iconita: ''
              };
              return (
                <CardFacultate
                  className="card"
                  key={index}
                  text={facultateObject.nume}
                  iconita={facultateObject.iconita}
                  onClick={() => {
                    setActivateTransition(!activateTransition);
                    setTimeout(
                      () =>
                        navigate(`/facultate/${facultate.id}`, {
                          state: {
                            id: facultate.shortName,
                            denumireFacultate: facultate.longName,
                          },
                        }),
                      300,
                    );
                  }}
                />
              );
            })}
        </ContainerCarduriFacultati>
        {!facultati.length && (
          <div style={{ position: 'absolute', top: '20%', left: '45%', zIndex: 2 }}>
            <Spinner isSpinning />
          </div>
        )}
      </GlobalContainer>
    </>
  );
}

export default MobileFacultyPage;
