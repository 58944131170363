import styled from 'styled-components';
import Culori from '../../constante/Culori';
import DimensiuniText from '../../constante/DimensiuniText';

export const DropdownStilizat = styled.div`
  width: 344px;

  .selected-option {
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    margin: 5px 0;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    flex-grow: 1;
    background-color: white;
  }

  .options {
    position: absolute;
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    overflow: hidden;
    color: ${Culori.albastru};
    overflow-y: scroll;
    font-size: ${DimensiuniText.text4};
    font-weight: 400;
    width: 344px;
    z-index: 999;
  }

  .options .option {
    cursor: pointer;
    padding: 10px 16px;
  }

  .options .option:hover {
    background-color: ${Culori.fundal};
  }

  .options .option:active {
    background-color: ${Culori.bordura};
  }

  .options .option.selected {
    background-color: ${Culori.bordura};
  }

  .input {
    outline: none;
    border: none;
    font-size: ${DimensiuniText.text4};
    font-weight: 400;
    padding: 7.5px 16px;
    color: ${Culori.albastru};
    border-radius: 10px;
    width: 100%;
    z-index: 1;
  }

  input::placeholder {
    color: ${Culori.placeholder};
    font-weight: 400;
  }

  @media (max-width: 834px) {
    .selected-option {
      display: none;
    }
  }
`;

export const ArrowDown = styled.img`
cursor: pointer;
    margin-right: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    rotate: ${(props) => (props.isOpen ? '180deg' : '0deg')};
    transition: rotate 0.3s;
    z-index: 1;
    &:focus {
      outline: none;
    }
  }
`;
