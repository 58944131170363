import styled from 'styled-components';
import Culori from '../../../constante/Culori';

export const DropdownContainer = styled.button`
  display: flex;
  padding: 0 19px;
  align-items: center;
  min-height: 50px;
  padding: 14px 19px;
  gap: 16px;
  border-radius: 10px;
  background-color: ${Culori.alb};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border: none;
  outline: none;

  &:active {
    opacity: 0.5;
  }
`;

export const DropdownIcon = styled.img`
  width: 19px;
  height: 19px;
  flex-shrink: 0;
`;

export const DropdownText = styled.p`
  color: ${Culori.albastru};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

export const DropdownArrow = styled.img`
  margin-left: auto;
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
`;

export const MoreInfo = styled.div`
  height: ${({ show }) => (show ? '100%' : '0px')};
  transition: height 0.3s ease-in-out;
  padding: 0;

  p {
    color: ${Culori.albastru};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
  }
`;
