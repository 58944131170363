import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  ContainerCarduriFacultati,
  GlobalContainer,
  Selector,
} from '../paginaAcasa/PaginaAcasa.styles';
import Antet from '../../componente/antet/Antet';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown2 from '../../componente/dropdown2/Dropdown2';
import {
  ContainerContinutPaginaFacultate,
  ItemButton,
  ItemsList,
  MainContainerMobile,
  MobileContainer,
  MobileContent,
} from '../paginaFacultate/PaginaFacultate.styles';
import Legenda from '../../componente/legenda/Legenda';
import useWindowDimensions from '../../hooks/useDimensions';
import Spinner from '../../componente/spinner/Spinner';
import IlustratieOrar from '../../resurse/ilustratii/ilustratieOrar.svg';
import MobileIlustration from '../../resurse/ilustratii/mobile-ilustration.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getOrarProfesor, getOrarSala } from '../../redux/actions/orarActions';
import { useTranslation } from 'react-i18next';
import ScheduleList from '../../componente/sheduleList/ScheduleList';
import MobileDropdown from '../../componente/mobileDropdown/MobileDropdown';
import { getCadreDidactice } from '../../redux/actions/cadreDidacticeActions';
import { getSali } from '../../redux/actions/saliActions';
import Dropdown from '../../componente/mobile/dropdown/Dropdown';
import SearchInput from '../../componente/mobile/search-input/SearchInput';
import Switch from '../../componente/mobile/switch/Switch';
import { replaceDiactritics } from '../../common/utils';
import Culori from '../../constante/Culori';
import './profesori.css';

function PaginaProfesori() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { idProfesor, idSala } = useParams();

  const { cadreDidactice } = useSelector((state) => state.cadreDidactice);
  const { sali } = useSelector((state) => state.sali);
  const { orar, loading, orarHtml } = useSelector((state) => state.orar);

  const [ore, setOre] = useState([]);
  const [activateTransition, setActivateTransition] = useState(false);
  const [activateListTransition, setActivateListTransition] = useState(false);
  const [isContentHidden, setIsContentHidden] = useState(true);
  const [isListContentHidden, setIsListContentHidden] = useState(true);

  const [listaSali, setListaSali] = useState([]);
  const [listaCadreDidactice, setListaCadreDidactice] = useState([]);
  const [comutareSwitch, setComutareSwitch] = useState(1);
  const [pageTitle, setPageTitle] = useState('');

  const [searchedText, setSearchedText] = useState('');
  const inputRef = useRef(null);

  // Tooltip functionality
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseOver = (content, event) => {
    setTooltipContent(content);
    setTooltipPosition({ x: event.clientX, y: event.clientY });
    setTooltipVisible(true);
  };

  const handleMouseOut = () => {
    setTooltipVisible(false);
  };

  useEffect(() => {
    const handleMouseMoveDocument = (event) => {
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      if (tooltipVisible) {
        setTooltipPosition({ x: event.clientX + scrollX, y: event.clientY + scrollY });
      }
    };

    document.addEventListener('mousemove', handleMouseMoveDocument);

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveDocument);
    };
  }, [tooltipVisible]);

  useEffect(() => {
    const links = document.querySelectorAll('a[onMouseOver]');
    links.forEach((link) => {
      const onMouseOverAttr = link.getAttribute('onMouseOver');
      link.removeAttribute('onMouseOver');
      link.onmouseover = (event) => {
        // Extract content properly from onMouseOverAttr if necessary
        const content = onMouseOverAttr.slice('showTooltip('.length, -1).replace(/['"]+/g, '');
        handleMouseOver(content, event);
      };

      link.onmouseout = handleMouseOut;
    });
  }, [orarHtml, ore, orar]); // Depend on the HTML string, if it changes you need to rebind

  const handleSelectProfesori = (option) => {
    dispatch(getOrarProfesor(option.id));
    navigate(`/profesori/${option.id}`);
  };

  const handleSelectSali = (option) => {
    dispatch(getOrarSala(option.id));
    navigate(`/sali/${option.id}`);
  };

  const sortareListaCadreDidactice = useCallback(() => {
    if (cadreDidactice.length === 0) return;
    let cadreDidacticeCopy = [...cadreDidactice];
    cadreDidacticeCopy.sort((a, b) => {
      const lastNameComparison = a.lastName.localeCompare(b.lastName);
      if (lastNameComparison !== 0) return lastNameComparison;
      return a.firstName.localeCompare(b.firstName);
    });

    setListaCadreDidactice(cadreDidacticeCopy.slice(1, cadreDidacticeCopy.length));
  }, [cadreDidactice]);

  const sortareListaSali = useCallback(() => {
    if (sali.length === 0) return;
    let saliCopy = [...sali];
    saliCopy?.sort((a, b) => {
      const buildingNameComparison = a.buildingName.localeCompare(b.buildingName);
      if (buildingNameComparison !== 0) return buildingNameComparison;
      return a.name.localeCompare(b.name);
    });

    setListaSali(saliCopy?.slice(1, saliCopy?.length));
  }, [sali]);

  function extractTableContent(htmlString) {
    // Replace colors in the string with corresponding variables
    const htmlStringCopy = htmlString
      .replace(/#ccbbbb/g, Culori.seminar)
      .replace(/#9999aa/g, Culori.curs)
      .replace(/#ccddcc/g, Culori.laborator)
      .replace(/#eeeeee/g, Culori.optional)
      .replace(/border:2px solid black/g, `border:2px solid ${Culori.albastru} !important`)
      .replace(/color='blue'/g, `color='${Culori.albastru}' !important`)
      .replace(/<a href='#'/g, '<a')
      .replace(/border="1"/g, '')
      .replace(/onmouseover="Tip\((.*?)\)"/g, `onMouseOver="showTooltip($1)"`)
      .replace(/onmouseout="UnTip\(\)"/g, 'onMouseOut="hideTooltip()"');

    const allTables = htmlStringCopy.match(/<table.*?>[\s\S]*?<\/table>/gi);

    // Check if tables are found
    if (allTables && allTables.length > 0) {
      // If there is more than one table, remove the last one
      if (allTables.length > 1) {
        allTables.pop(); // Remove the last table from the array
      }

      // Return the HTML string with the last table removed by joining remaining tables
      return allTables.join('\n');
    }

    return 'Nu sunt date de afișat...';
  }

  useEffect(() => {
    dispatch(getCadreDidactice());
    dispatch(getSali());
    if (idProfesor) {
      dispatch(getOrarProfesor(idProfesor));
    }
    if (idSala) {
      // setComutareSwitch(false);
      dispatch(getOrarSala(idSala));
    }
    if (!idProfesor && !idSala) {
      dispatch(getOrarProfesor(null));
      dispatch(getOrarSala(null));
    }
  }, [dispatch, idProfesor, idSala]);

  useEffect(() => {
    sortareListaCadreDidactice();
    sortareListaSali();
  }, [cadreDidactice, sali, sortareListaCadreDidactice, sortareListaSali]);

  useEffect(() => {
    setIsContentHidden(false);
  }, []);

  useEffect(() => {
    window.location.pathname.includes('sali') ? setComutareSwitch(2) : setComutareSwitch(1);
    //eslint-disable-next-line
  }, [window.location.pathname]);

  useEffect(() => {
    setOre(orar);
  }, [orar]);

  useEffect(() => {
    if (activateListTransition) {
      inputRef.current.focus();
    } else {
      setSearchedText('');
    }
  }, [activateListTransition]);

  useEffect(() => {
    return () => {
      if (ore?.length > 0) {
        setOre([]);
        dispatch(getOrarProfesor(null));
        dispatch(getOrarSala(null));
      }
    };
  }, [dispatch, ore?.length]);

  useEffect(() => {
    if ((idProfesor && cadreDidactice.length > 0) || (idSala && sali.length > 0)) {
      const item = window.location.href.includes('sali')
        ? sali.filter((sala) => sala.id === idSala)
        : cadreDidactice.filter((profesor) => profesor.id === idProfesor);
      setPageTitle(
        window.location.href.includes('sali')
          ? `${item[0]?.buildingName ? `Corp ${item[0]?.buildingName}` : ''}${
              item[0]?.name && item[0]?.buildingName ? ' - ' : ''
            }${item[0]?.name}`
          : `${item[0]?.lastName} ${item[0]?.firstName}`,
      );
    } else {
      setPageTitle(window.location.href.includes('sali') ? t('Săli') : t('Profesori'));
    }
  }, [idProfesor, idSala, cadreDidactice, sali, t]);

  return (
    <>
      <Antet title={pageTitle} showArrow />
      {/* WEB */}
      <GlobalContainer>
        <MainContainerMobile>
          <Selector className="selector">
            <Switch
              options={[t('profesori'), t('sali')]}
              setSelectedOption={setComutareSwitch}
              selectedOption={comutareSwitch}
              activateTransition={true}
              hidden={true}
            />
            <Dropdown2
              className="switch"
              placeholder={comutareSwitch === 1 ? t('selectareProfesor') : t('selectareSala')}
              handleSelect={comutareSwitch === 1 ? handleSelectProfesori : handleSelectSali}
              options={
                comutareSwitch === 1
                  ? listaCadreDidactice.map((profesor) => {
                      return {
                        id: profesor.id,
                        nume: profesor?.lastName + ' ' + profesor?.firstName,
                      };
                    })
                  : listaSali.map((sala) => {
                      return {
                        id: sala.id,
                        nume: `${sala?.buildingName ? `Corp ${sala?.buildingName}` : ''}${
                          sala?.name && sala?.buildingName ? ' - ' : ''
                        }${sala?.name}`,
                      };
                    })
              }
            />
            <MobileDropdown
              placeholder={comutareSwitch ? t('selectareProfesor') : t('selectareSala')}
              handleSelect={comutareSwitch ? handleSelectProfesori : handleSelectSali}
              options={
                comutareSwitch
                  ? listaCadreDidactice.map((profesor) => {
                      return {
                        id: profesor.id,
                        nume: profesor?.lastName + ' ' + profesor?.firstName,
                      };
                    })
                  : listaSali.map((sala) => {
                      return {
                        id: sala.id,
                        nume: `${sala?.buildingName ? `Corp ${sala?.buildingName}` : ''}${
                          sala?.name && sala?.buildingName ? ' - ' : ''
                        }${sala?.name}`,
                      };
                    })
              }
            />
          </Selector>
          <ContainerCarduriFacultati>
            <ContainerContinutPaginaFacultate>
              {ore && ore[0]?.length > 0 ? (
                <>
                  {width > 834 && tooltipVisible && (
                    <div
                      id="tooltip"
                      className="toolTipContainer"
                      style={{
                        position: 'absolute',
                        left: `${tooltipPosition.x + 10}px`,
                        top: `${tooltipPosition.y + 10}px`,
                        opacity: tooltipVisible ? 1 : 0,
                      }}
                      dangerouslySetInnerHTML={{ __html: tooltipContent }}
                    />
                  )}
                  {width > 834 && (
                    <div dangerouslySetInnerHTML={{ __html: extractTableContent(orarHtml) }} />
                  )}
                  <Legenda />
                </>
              ) : (
                <div style={{ margin: '0 auto', userSelect: 'none' }}>
                  {idSala || idProfesor ? (
                    loading ? (
                      <Spinner isSpinning />
                    ) : (
                      <p>Nu sunt încă programate activități didactice</p>
                    )
                  ) : (
                    <img src={IlustratieOrar} alt="Ilustrație orar" />
                  )}
                </div>
              )}
            </ContainerContinutPaginaFacultate>
          </ContainerCarduriFacultati>
        </MainContainerMobile>
        {/* Mobile */}
        <MobileContainer hidden={isContentHidden}>
          <SearchInput
            activateTransition={activateListTransition}
            hidden={isListContentHidden}
            value={searchedText}
            setValue={setSearchedText}
            inputRef={inputRef}
          />
          <MobileContent activateTransition={activateTransition}>
            <Dropdown
              text={
                window.location.href.includes('sali') ? 'Selectează sala' : 'Selectează profesorul'
              }
              onClick={() => {
                setActivateTransition(!activateTransition);

                setTimeout(() => {
                  setActivateListTransition(!activateListTransition);
                }, 100);
                setTimeout(() => {
                  setIsListContentHidden(false);
                }, 100);
              }}
            />
            <ContainerContinutPaginaFacultate>
              {ore && ore[0]?.length ? (
                <>
                  <ScheduleList ore={ore[0]} oreData={ore[1]} />
                  <Legenda />
                </>
              ) : (
                <div style={{ margin: '0 auto', userSelect: 'none' }}>
                  {window.location.href.includes('sali') ? (
                    idSala ? (
                      loading ? (
                        <Spinner isSpinning />
                      ) : (
                        <p>Nu sunt încă programate activități didactice</p>
                      )
                    ) : (
                      <img src={MobileIlustration} alt="Ilustrație orar" />
                    )
                  ) : idProfesor ? (
                    loading ? (
                      <Spinner isSpinning />
                    ) : (
                      <p>Nu sunt încă programate activități didactice</p>
                    )
                  ) : (
                    <img src={MobileIlustration} alt="Ilustrație orar" />
                  )}
                </div>
              )}
            </ContainerContinutPaginaFacultate>
          </MobileContent>
          <ItemsList
            activateTransition={activateListTransition}
            hidden={isListContentHidden}
            style={{ top: '145px', maxHeight: 'calc(100vh - 220px)' }}
          >
            {window.location.href.includes('sali')
              ? listaSali
                  .filter((sala) =>
                    JSON.stringify(
                      replaceDiactritics(
                        `${sala?.buildingName ? `Corp ${sala?.buildingName}` : ''}${
                          sala?.name && sala?.buildingName ? ' - ' : ''
                        }${sala?.name}`,
                      ),
                    )
                      .toLowerCase()
                      .includes(searchedText.toLowerCase()),
                  )
                  .map((sala, index) => {
                    return (
                      <ItemButton
                        key={index}
                        onClick={() => {
                          handleSelectSali(sala);
                          setActivateTransition(!activateTransition);
                          setActivateListTransition(!activateListTransition);
                        }}
                      >
                        {`${sala?.buildingName ? `Corp ${sala?.buildingName}` : ''}${
                          sala?.name && sala?.buildingName ? ' - ' : ''
                        }${sala?.name}`}
                      </ItemButton>
                    );
                  })
              : listaCadreDidactice
                  .slice(1)
                  .filter((prof) =>
                    JSON.stringify(replaceDiactritics(prof?.lastName + ' ' + prof?.firstName))
                      .toLowerCase()
                      .includes(searchedText.toLowerCase()),
                  )
                  .map((profesor, index) => {
                    return (
                      <ItemButton
                        key={index}
                        onClick={() => {
                          handleSelectProfesori(profesor);
                          setActivateTransition(!activateTransition);
                          setActivateListTransition(!activateListTransition);
                        }}
                      >
                        {profesor?.lastName + ' ' + profesor?.firstName}
                      </ItemButton>
                    );
                  })}
          </ItemsList>
        </MobileContainer>
      </GlobalContainer>
    </>
  );
}

export default PaginaProfesori;
