export const GET_ORAR_GRUPA_REQUEST = 'GET_ORAR_GRUPA_REQUEST';
export const GET_ORAR_GRUPA_SUCCESS = 'GET_GRUPA_SUCCESS';
export const GET_ORAR_GRUPA_FAILURE = 'GET_ORAR_GRUPA_FAILURE';

export const GET_ORAR_PROFESOR_REQUEST = 'GET_ORAR_PROFESOR_REQUEST';
export const GET_ORAR_PROFESOR_SUCCESS = 'GET_ORAR_PROFESOR_SUCCESS';
export const GET_ORAR_PROFESOR_FAILURE = 'GET_ORAR_PROFESOR_FAILURE';

export const GET_ORAR_SALA_REQUEST = 'GET_ORAR_SALA_REQUEST';
export const GET_ORAR_SALA_SUCCESS = 'GET_ORAR_SALA_SUCCESS';
export const GET_ORAR_SALA_FAILURE = 'GET_ORAR_SALA_FAILURE';

export const GET_CURRENT_WEEK = 'GET_CURRENT_WEEK';

export function getOrarGrupaRequest() {
  return { type: GET_ORAR_GRUPA_REQUEST };
}

export const getOrarGrupaSuccess = (orarSubgrupa) => ({
  type: GET_ORAR_GRUPA_SUCCESS,
  orarSubgrupa,
});

export const getOrarGrupaFailure = (error) => ({
  type: GET_ORAR_GRUPA_FAILURE,
  error: error.message,
});

export function getOrarProfesorRequest() {
  return { type: GET_ORAR_PROFESOR_REQUEST };
}

export const getOrarProfesorSuccess = (orarProfesor) => ({
  type: GET_ORAR_PROFESOR_SUCCESS,
  orarProfesor,
});

export const getOrarProfesorFailure = (error) => {
  return {
    type: GET_ORAR_PROFESOR_FAILURE,
    error: error.toString(),
  };
};

export function getOrarSalaRequest() {
  return { type: GET_ORAR_SALA_REQUEST };
}

export function setOrarHtml(orarHtml) {
  return { type: 'SET_ORAR_HTML', orarHtml };
}

export const getOrarSalaSuccess = (orarSala) => ({
  type: GET_ORAR_SALA_SUCCESS,
  orarSala,
});

export const getOrarSalaFailure = (error) => ({
  type: GET_ORAR_SALA_FAILURE,
  error,
});

export const getCurrentWeek = (currentWeek) => ({
  type: GET_CURRENT_WEEK,
  currentWeek,
});

function orarUrlEndpoint(isModular) {
  return isModular ? `orarFormatiiM.php` : `orarSPG.php`;
}

export function getOrarGrupa(idGrupa, isModular) {
  // console.log(">>>>", isModular)
  // console.trace();
  // console.log("_----------------_");
  return async (dispatch) => {
    dispatch(getOrarGrupaRequest());
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/orarSPG.php?mod=grupa&ID=${idGrupa}&json`,
        // `${orarUrlPrefix(isModular)}?mod=grupa&ID=${idGrupa}&json`
      );
      const data = await response.json();
      let newData = [
        data[0].sort((a, b) => a.startHour - b.startHour).sort((a, b) => a.weekDay - b.weekDay),
        data[1],
      ];
      dispatch(getOrarGrupaSuccess(newData));
    } catch (error) {
      dispatch(getOrarGrupaFailure(error));
    }
    try {
      const response2 = await fetch(
        isModular ?
        `${process.env.REACT_APP_API_URL_WITHOUT_DATA}/${orarUrlEndpoint(isModular)}?mod=vizual&print=nu&orarID=${isModular}&an=24_25&sem=1&rand=0.9315300124767769` :
        `${process.env.REACT_APP_API_URL_WITHOUT_DATA}/orarSPG.php?mod=grupa&ID=${idGrupa}&mod2=vizual&print=nu&back=&an=24_25&sem=1&rand=0.12072948550468676`,
        // `${orarUrlPrefix(isModular)}?mod=grupa&ID=${idGrupa}&mod2=vizual&print=nu&back=&an=23_24&sem=2&rand=0.12072948550468676`,
      );
      const data2 = await response2.text();
      dispatch(setOrarHtml(extractTableContent(data2, true)));
    } catch (e) {}
  };
}

export function getOrarProfesor(idProfesor) {
  return async (dispatch) => {
    dispatch(getOrarProfesorRequest());
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/orarSPG.php?mod=prof&ID=${idProfesor}&json`,
      );
      const jsonString = await response.text();
      const escapedJsonString = jsonString.replace(/\n/g, '\\n');
      const json = await JSON.parse(escapedJsonString);
      let newJson = [
        json[0].sort((a, b) => a.startHour - b.startHour).sort((a, b) => a.weekDay - b.weekDay),
        json[1],
      ];
      dispatch(getOrarProfesorSuccess(newJson));
    } catch (error) {
      dispatch(getOrarProfesorFailure(error));
    }
    try {
      const response2 = await fetch(
        `${process.env.REACT_APP_API_URL_WITHOUT_DATA}/orarSPG.php?mod=prof&ID=${idProfesor}&mod2=vizual&print=nu&back=&an=23_24&sem=2&rand=0.12072948550468676`,
      );
      const data2 = await response2.text();
      dispatch(setOrarHtml(extractTableContent(data2)));
    } catch (e) {}
  };
}

function extractTableContent(htmlString, log) {
  // This regular expression matches content between the first <table> and the last </table> tag
  const tableRegex = /<table[^>]*>([\s\S]*?)<\/table>/gi;
  let result = '';
  let match;

  // Loop through all matches and concatenate to result
  while ((match = tableRegex.exec(htmlString)) !== null) {
    result += match[0] + '\n'; // Adds a newline after each table for readability if multiple tables
  }
  return result;
}

export function getOrarSala(idSala) {
  return async (dispatch) => {
    dispatch(getOrarSalaRequest());
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/orarSPG.php?mod=sala&ID=${idSala}&json`,
      );

      const json = await response.json();
      const newJson = [
        json[0].sort((a, b) => a.startHour - b.startHour).sort((a, b) => a.weekDay - b.weekDay),
        json[1],
      ];
      dispatch(getOrarSalaSuccess(newJson));
    } catch (error) {
      dispatch(getOrarSalaFailure(error));
    }
    try {
      const response2 = await fetch(
        `${process.env.REACT_APP_API_URL_WITHOUT_DATA}/orarSPG.php?mod=sala&ID=${idSala}&mod2=vizual&print=nu&back=&an=23_24&sem=2&rand=0.12072948550468676`,
      );
      const data2 = await response2.text();
      dispatch(setOrarHtml(extractTableContent(data2)));
    } catch (e) {}
  };
}

export function getCurrentWeekNumber() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/anSemSapt.php?json`);
      const json = await response.json();
      dispatch(getCurrentWeek(json));
    } catch (error) {
      console.log(error);
    }
  };
}
