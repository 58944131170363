import './App.css';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PaginaAcasa from './pagini/paginaAcasa/PaginaAcasa';
import PaginaFacultate from './pagini/paginaFacultate/PaginaFacultate';
import PaginaInformatii from './pagini/paginaInformatii/PaginaInformatii';
import PaginaProfesori from './pagini/paginaProfesori/PaginaProfesori';
import { useDispatch } from 'react-redux';
import { getCadreDidactice } from './redux/actions/cadreDidacticeActions';
import { getFacultati } from './redux/actions/facultatiActions';
import { useEffect } from 'react';
import { getSali } from './redux/actions/saliActions';
import { getSubgrupe } from './redux/actions/subgrupeActions';
import i18n from './config/i18n';
import { getCurrentWeekNumber } from './redux/actions/orarActions';
import MobileFacultyPage from './pagini/mobile-faculty-page/MobileFacultyPage';
import PaginaOrareSalvate from './pagini/paginaOrareSalvate/PaginaOrareSalvate';

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  body {
    font-family: 'Open Sans', sans-serif;
  }
`;

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubgrupe());
    dispatch(getFacultati());
    dispatch(getCadreDidactice());
    dispatch(getSali());
    dispatch(getCurrentWeekNumber());
    dispatch(getSubgrupe());
  }, [dispatch]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language') || 'ro');
  }, []);

  return (
    <BrowserRouter>
      <GlobalStyle />

      <Routes>
        <Route exact path="/" element={<PaginaAcasa />} />
        <Route exact path="/*" element={<Navigate to="/" />} />
        <Route exact path="/profesori" element={<PaginaProfesori />} />
        <Route exact path="/sali" element={<PaginaProfesori />} />
        <Route exact path="/profesori/:idProfesor" element={<PaginaProfesori />} />
        <Route exact path="/sali/:idSala" element={<PaginaProfesori />} />
        <Route exact path="/facultate" element={<MobileFacultyPage />} />
        <Route exact path="/facultate/:idFacultate" element={<PaginaFacultate />} />
        <Route
          exact
          path="/facultate/:idFacultate/semigrupa/:idSemigrupa"
          element={<PaginaFacultate />}
        />
        <Route exact path="/info" element={<PaginaInformatii />} />
        <Route exact path="/orare-salvate" element={<PaginaOrareSalvate />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
