export const GET_FACULTATI_REQUEST = 'GET_FACULTATI_REQUEST';
export const GET_FACULTATI_SUCCESS = 'GET_FACULTATI_SUCCESS';
export const GET_FACULTATI_FAILURE = 'GET_FACULTATI_FAILURE';

export function getFacultatiRequest() {
  return { type: GET_FACULTATI_REQUEST };
}

export function getFacultatiSuccess(facultati) {
  return { type: GET_FACULTATI_SUCCESS, facultati };
}

export function getFacultatiFailure(error) {
  return { type: GET_FACULTATI_FAILURE, error };
}

export function getFacultati() {
  return async (dispatch) => {
    dispatch(getFacultatiRequest());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/facultati.php?json`);
      const data = await response.json();
      dispatch(getFacultatiSuccess(data));
    } catch (error) {
      console.log('error getFacultati', error);
    }
  };
}
