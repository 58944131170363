import { combineReducers } from 'redux';
import cadreDidacticeReducer from './reducers/cadreDidacticeReducer';
import facultatiReducer from './reducers/facultatiReducer';
import saliReducer from './reducers/saliReducer';
import subgrupeReducer from './reducers/subgrupeReducer';
import orarReducer from './reducers/orarReducer';

const rootReducer = combineReducers({
  cadreDidactice: cadreDidacticeReducer,
  facultati: facultatiReducer,
  sali: saliReducer,
  subgrupe: subgrupeReducer,
  orar: orarReducer,
});

export default rootReducer;
