import styled from 'styled-components';
import Culori from '../../constante/Culori';

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 0 0 16px 0;

  @media (max-width: 834px) {
    // margin: 0 22px 64px 22px;
    // padding: 100px 0 30px 0;
    display: none;
  }
`;

export const MainContainerMobile = styled.div`
  @media (max-width: 834px) {
    // margin: 0 22px 64px 22px;
    // padding: 100px 0 30px 0;
    display: none;
  }
`;

export const ContainerContinutPaginaFacultate = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
  flex-wrap: wrap;

  img {
    height: 390px;
    max-width: 100%;
    object-fit: contain;
    padding-top: 40px;
  }

  p {
    margin: 10px;
    padding: 0;
    font-size: 16px;
    color: ${Culori.albastru};
  }

  @media (max-width: 834px) {
    margin: 0 0 64px 0;
  }
`;

export const Selector = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 1641px) {
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 834px) {
    justify-content: center;
  }
`;

export const ContainerOptiuni = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  row-gap: 9px;
  gap: 20px;

  .switch {
    // margin-left: auto;
  }
  // @media (max-width: 900px) {
  //   justify-content: center;

  //   .switch {
  //     margin-left: 0;
  //   }
  // }
`;

// Mobile styles
export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin: 0 22px 64px 22px;
  padding: 88px 0;
  height: calc(100vh - 70px);
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: all 0.3s ease-in-out;

  @media (min-width: 834px) {
    display: none;
  }
`;

export const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: ${({ activateTransition }) =>
    activateTransition ? 'translateX(-110%)' : 'translateX(0%)'};
  transition: transform 0.3s ease-in-out;

  @media (min-width: 834px) {
    display: none;
  }
`;

export const ItemsList = styled.div`
  position: absolute;
  top: 204px;
  width: calc(100% - 44px);
  flex-direction: column;
  padding: 0px 2px 0 2px;
  gap: 16px;
  border-radius: 18px;
  padding-bottom: 100px;
  display: ${({ activateTransition }) => (activateTransition ? 'flex' : 'none')};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transform: ${({ activateTransition }) =>
    activateTransition ? 'translateX(0)' : 'translateX(110%)'};
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
  max-height: calc(100vh - 210px);

  @media (min-width: 834px) {
    display: none;
  }
`;

export const ItemButton = styled.button`
  display: flex;
  padding: 20px 19px;
  align-items: center;
  justify-content: center;
  gap: 14px;
  border-radius: 10px;
  background-color: ${Culori.alb};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border: none;
  outline: none;
  color: ${Culori.albastru};
  text-align: center;

  &:active {
    opacity: 0.5;
  }
`;
