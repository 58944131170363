import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Antet from '../../componente/antet/Antet';
import {
  ContainerContinutPaginaFacultate,
  ContainerOptiuni,
  MainContainer,
  MobileContainer,
  MobileContent,
  Selector,
  ItemsList,
  ItemButton,
} from './PaginaFacultate.styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Legenda from '../../componente/legenda/Legenda';
import Dropdown2 from '../../componente/dropdown2/Dropdown2';
import useWindowDimensions from '../../hooks/useDimensions';
import IlustratieOrar from '../../resurse/ilustratii/ilustratieOrar.svg';
import MobileIlustration from '../../resurse/ilustratii/mobile-ilustration.svg';
import { FACULTATE } from '../../constante/Facultati';
import Spinner from '../../componente/spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { getOrarGrupa, getOrarGrupaSuccess } from '../../redux/actions/orarActions';
import ScheduleList from '../../componente/sheduleList/ScheduleList';
import { replaceDiactritics } from '../../common/utils';
import Switch from '../../componente/mobile/switch/Switch';
import Dropdown from '../../componente/mobile/dropdown/Dropdown';
import SearchInput from '../../componente/mobile/search-input/SearchInput';
import { GlobalContainer } from '../paginaAcasa/PaginaAcasa.styles';
import '../paginaProfesori/profesori.css';
import Culori from '../../constante/Culori';

function PaginaFacultate() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [ore, setOre] = useState([]);
  const { subgrupe } = useSelector((state) => state.subgrupe);
  const { orar, loading, orarHtml } = useSelector((state) => state.orar);
  const [activateTransition, setActivateTransition] = useState(false);
  const [activateListTransition, setActivateListTransition] = useState(false);
  const [isContentHidden, setIsContentHidden] = useState(true);
  const [isListContentHidden, setIsListContentHidden] = useState(true);

  const [subgrupeSelectate, setSubgrupeSelectate] = useState([]);
  const [searchedText, setSearchedText] = useState('');
  const [pageTitle, setPageTitle] = useState('Facultați');
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [idProgramStudiu, setIdProgramStudiu] = useState(1);
  //eslint-disable-next-line
  const [idSemigrupaOrare, setIdSemigrupaOrare] = useState(0);
  const [actualizare, setActualizare] = useState(false);
  const { idFacultate, idSemigrupa } = useParams();

  // Tooltip functionality
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseOver = (content, event) => {
    setTooltipContent(content);
    setTooltipPosition({ x: event.clientX, y: event.clientY });
    setTooltipVisible(true);
  };

  const handleMouseOut = () => {
    setTooltipVisible(false);
  };

  useEffect(() => {
    const handleMouseMoveDocument = (event) => {
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      if (tooltipVisible) {
        setTooltipPosition({ x: event.clientX + scrollX, y: event.clientY + scrollY });
      }
    };

    document.addEventListener('mousemove', handleMouseMoveDocument);

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveDocument);
    };
  }, [tooltipVisible]);

  useEffect(() => {
    const links = document.querySelectorAll('a[onMouseOver]');
    links.forEach((link) => {
      const onMouseOverAttr = link.getAttribute('onMouseOver');
      link.removeAttribute('onMouseOver');
      link.onmouseover = (event) => {
        // Extract content properly from onMouseOverAttr if necessary
        const content = onMouseOverAttr.slice('showTooltip('.length, -1).replace(/['"]+/g, '');
        handleMouseOver(content, event);
      };

      link.onmouseout = handleMouseOut;
    });
  }, [orarHtml, ore, orar]); // Depend on the HTML string, if it changes you need to rebind

  const { t } = useTranslation();

  const handleSelect = (option) => {
    if (!option) return;
    dispatch(getOrarGrupa(option.id, option.isModular ? option.orarId : false));
    setActualizare(!actualizare);
    navigate(`/facultate/${idFacultate}/semigrupa/${option.id}`);
  };

  useEffect(() => {
    setOre(orar);
  }, [orar]);

  function extractTableContent(htmlString) {
    // Replace colors in the string with corresponding variables
    const htmlStringCopy = htmlString
      .replace(/#ccbbbb/g, Culori.seminar)
      .replace(/#9999aa/g, Culori.curs)
      .replace(/#ccddcc/g, Culori.laborator)
      .replace(/#eeeeee/g, Culori.optional)
      .replace(/border:2px solid black/g, `border:2px solid ${Culori.albastru} !important`)
      .replace(/color='blue'/g, `color='${Culori.albastru}' !important`)
      .replace(/<a href='#'/g, '<a')
      .replace(/border="1"/g, '')
      .replace(/onmouseover="Tip\((.*?)\)"/g, `onMouseOver="showTooltip($1)"`)
      .replace(/onmouseout="UnTip\(\)"/g, 'onMouseOut="hideTooltip()"');

    const allTables = htmlStringCopy.match(/<table.*?>[\s\S]*?<\/table>/gi);

    // console.log(allTables);

    // Check if tables are found
    if (allTables && allTables.length > 0) {
      // If there is more than one table, remove the last one
      if (allTables.length > 1) {
        allTables.pop(); // Remove the last table from the array
      }

      // Return the HTML string with the last table removed by joining remaining tables
      return allTables.join('\n');
    }

    return 'Nu sunt date de afișat...';
  }

  useEffect(() => {
    setSubgrupeSelectate(subgrupe);
  }, [subgrupe]);

  useEffect(() => {
    if (idSemigrupa) {

      let orarId = false;
      for (let i = 0; i < subgrupe.length; i++) {
        if (subgrupe[i].id == idSemigrupa) {
          orarId = Number(subgrupe[i].isModular) ? subgrupe[i].orarId : false;
          // console.log(subgrupe[i], orarId);
          break;
        }
      }

      // option.id, option.isModular ? option.orarId : false

      dispatch(getOrarGrupa(idSemigrupa, orarId));
    } else {
      dispatch(getOrarGrupaSuccess(null));
    }
  }, [actualizare, location.pathname, idSemigrupa, dispatch]);

  useEffect(() => {
    setIsContentHidden(false);
  }, []);

  // Set page title
  useEffect(() => {
    if (idSemigrupa && subgrupe.length > 0) {
      //eslint-disable-next-line
      const currentSubgrupa = subgrupe.find((subgrupa) => subgrupa.id == idSemigrupa);
      setPageTitle(
        currentSubgrupa.specializationShortName +
          ' • an ' +
          currentSubgrupa.studyYear +
          ' • ' +
          currentSubgrupa.groupName +
          currentSubgrupa.subgroupIndex.toUpperCase(),
      );
    } else {
      setPageTitle(replaceDiactritics(FACULTATE[idFacultate - 1]).replace(/[a-z/,\s]/g, ''));
    }
  }, [idSemigrupa, subgrupe, idFacultate]);

  useEffect(() => {
    if (!activateListTransition) setSearchedText('');
  }, [activateListTransition]);

  useEffect(() => {
    return () => {
      dispatch(getOrarGrupaSuccess(null));
    };
  }, [dispatch]);

  return (
    <>
      <Antet title={pageTitle} showArrow />
      {/* WEB */}
      <GlobalContainer>
        <MainContainer>
          <ContainerOptiuni>
            <Selector>
              <Switch
                options={['Licență', 'Master', 'Postuniversitar']}
                setSelectedOption={setIdProgramStudiu}
                selectedOption={idProgramStudiu}
                activateTransition={!activateListTransition}
                hidden={!isListContentHidden}
              />
              <Dropdown2
                handleSelect={handleSelect}
                className="switch"
                placeholder={
                  idSemigrupaOrare === 1
                    ? `${
                        idProgramStudiu === 1
                          ? t('selectareSemigrupaLicenta')
                          : idProgramStudiu === 2
                          ? t('selectareSemigrupaMaster')
                          : t('selectareSemigrupaDoctorat')
                      }`
                    : `${
                        idProgramStudiu === 1
                          ? t('selectareOrareLicenta')
                          : idProgramStudiu === 2
                          ? t('selectareOrareMaster')
                          : t('selectareOrareDoctorat')
                      }`
                }
                options={subgrupeSelectate
                  .slice(1)
                  .filter(
                    (subgrupa) =>
                      //eslint-disable-next-line
                      subgrupa.type == idProgramStudiu && subgrupa.facultyId === idFacultate,
                  )
                  .map((subgrupa) => {
                    return {
                      id: subgrupa.id,
                      isModular: subgrupa.isModular || false,
                      orarId: subgrupa.orarId,
                      nume:
                        subgrupa.specializationShortName +
                        ' • an ' +
                        subgrupa.studyYear +
                        ' • ' +
                        subgrupa.groupName +
                        subgrupa.subgroupIndex,
                    };
                  })}
              />
            </Selector>
          </ContainerOptiuni>

          <ContainerContinutPaginaFacultate>
            {orar ? (
              <>
                {width > 834 && tooltipVisible && (
                  <div
                    id="tooltip"
                    className="toolTipContainer"
                    style={{
                      position: 'absolute',
                      left: `${tooltipPosition.x + 10}px`,
                      top: `${tooltipPosition.y + 10}px`,
                      opacity: tooltipVisible ? 1 : 0,
                    }}
                    dangerouslySetInnerHTML={{ __html: tooltipContent }}
                  />
                )}
                {width > 834 && (
                  <div dangerouslySetInnerHTML={{ __html: extractTableContent(orarHtml) }} />
                )}
                <Legenda />
              </>
            ) : (
              <div style={{ margin: '0 auto', userSelect: 'none' }}>
                {idSemigrupa ? (
                  loading ? (
                    <Spinner isSpinning />
                  ) : (
                    <p>Nu sunt încă programate activități didactice</p>
                  )
                ) : (
                  <img src={IlustratieOrar} alt="Ilustrație orar" />
                )}
              </div>
            )}
          </ContainerContinutPaginaFacultate>
        </MainContainer>
        {/* Mobile */}
        <MobileContainer hidden={isContentHidden}>
          <Switch
            options={['Licență', 'Master', 'Postuniversitar']}
            setSelectedOption={setIdProgramStudiu}
            selectedOption={idProgramStudiu}
            activateTransition={activateListTransition}
            hidden={isListContentHidden}
          />
          <SearchInput
            activateTransition={activateListTransition}
            hidden={isListContentHidden}
            value={searchedText}
            setValue={setSearchedText}
            inputRef={inputRef}
          />
          <ItemsList activateTransition={activateListTransition} hidden={isListContentHidden}>
            {subgrupeSelectate
              .slice(1)
              .filter(
                (subgrupa) =>
                  //eslint-disable-next-line
                  subgrupa.type == idProgramStudiu &&
                  subgrupa.facultyId === idFacultate &&
                  replaceDiactritics(
                    JSON.stringify(
                      subgrupa.specializationShortName +
                        ' • an ' +
                        subgrupa.studyYear +
                        ' • ' +
                        subgrupa.groupName +
                        subgrupa.subgroupIndex,
                    ).toLowerCase(),
                  ).includes(searchedText.toLowerCase()),
              )
              .map((subgrupa, index) => {
                return (
                  <ItemButton
                    key={index}
                    onClick={() => {
                      handleSelect(subgrupa);
                      setActivateTransition(!activateTransition);
                      setActivateListTransition(!activateListTransition);
                    }}
                  >
                    {subgrupa.specializationShortName +
                      ' • an ' +
                      subgrupa.studyYear +
                      ' • ' +
                      subgrupa.groupName +
                      subgrupa.subgroupIndex}
                  </ItemButton>
                );
              })}
            {subgrupeSelectate.slice(1).filter(
              (subgrupa) =>
                //eslint-disable-next-line
                subgrupa.type == idProgramStudiu && subgrupa.facultyId === idFacultate,
            ).length === 0 ? (
              <ItemButton>Nu există semigrupe pentru acest program de studiu</ItemButton>
            ) : null}
          </ItemsList>
          <MobileContent activateTransition={activateTransition}>
            <Dropdown
              text="Selectează semigrupa"
              onClick={() => {
                setActivateTransition(!activateTransition);
                dispatch(getOrarGrupaSuccess(null));
                setTimeout(() => {
                  setActivateListTransition(!activateListTransition);
                }, 100);
                setTimeout(() => {
                  setIsListContentHidden(false);
                }, 100);
              }}
            />
            <ContainerContinutPaginaFacultate>
              {orar && orar[0]?.length > 0 ? (
                <>
                  <ScheduleList ore={orar[0]} subgrupeSelectate={subgrupeSelectate} />
                  <Legenda />
                </>
              ) : (
                <div style={{ margin: '0 auto', userSelect: 'none' }}>
                  {idSemigrupa ? (
                    loading ? (
                      <Spinner isSpinning />
                    ) : (
                      <p>Nu sunt încă programate activități didactice</p>
                    )
                  ) : (
                    <img src={MobileIlustration} alt="Ilustrație orar" />
                  )}
                </div>
              )}
            </ContainerContinutPaginaFacultate>
          </MobileContent>
        </MobileContainer>
      </GlobalContainer>
    </>
  );
}

export default PaginaFacultate;
