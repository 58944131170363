import React, { useEffect, useState } from 'react';
import Antet from '../../componente/antet/Antet';
import {
  GlobalContainer,
  MobileContainer,
  MobileInfoContainer,
  MobileTitle,
} from '../paginaAcasa/PaginaAcasa.styles';
import useWindowSize from '../../utils/useWindowSize';
import { useNavigate } from 'react-router-dom';
import InfoCard from '../../componente/mobile/info-card/InfoCard';
import PeopleIcon from '../../resurse/iconiteInterfata/people.svg';
import PersonIcon from '../../resurse/iconiteInterfata/person.svg';
import ScheduleIcon from '../../resurse/iconiteInterfata/schedule-white.svg';
import SteaPlina from '../../resurse/iconiteInterfata/iconitaSteaPlina.svg';
import MobileIlustration from '../../resurse/ilustratii/mobile-ilustration.svg';

function PaginaOrareSalvate() {
  const [isContentHidden, setIsContentHidden] = useState(true);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [orareSalvate, setOrareSalvate] = useState([]);
  const [regetData, setRegetData] = useState(false);

  useEffect(() => {
    if (width > 834) {
      navigate('/');
    }
  }, [width, navigate]);

  useEffect(() => {
    if (localStorage.getItem('orareSalvate')) {
      setOrareSalvate(JSON.parse(localStorage.getItem('orareSalvate')));
    }
  }, [regetData]);

  useEffect(() => {
    setIsContentHidden(false);
  }, []);

  return (
    <>
      <Antet title="Orare Salvate" />
      <GlobalContainer>
        <MobileContainer hidden={isContentHidden}>
          {orareSalvate.length > 0 ? (
            <MobileInfoContainer>
              {orareSalvate
                .sort((a, b) => a.tip.localeCompare(b.tip))
                .map((orar, index) => (
                  <React.Fragment key={index}>
                    {orar.tip !== orareSalvate[index - 1]?.tip && (
                      <MobileTitle>{`Orare ${orar.tip}`}</MobileTitle>
                    )}
                    <InfoCard
                      id={orar.id}
                      setRegetData={setRegetData}
                      icon={
                        orar.tip === 'semigrupe'
                          ? PeopleIcon
                          : orar.tip === 'profesori'
                          ? PersonIcon
                          : ScheduleIcon
                      }
                      text={orar.titlu}
                      setOrareSalvate={setOrareSalvate}
                      onClick={() => {
                        setTimeout(() => {
                          navigate(orar.cale);
                        }, 300);
                      }}
                    />
                  </React.Fragment>
                ))}
            </MobileInfoContainer>
          ) : (
            <>
              <MobileTitle>
                <img
                  alt="Salveaza orar"
                  src={SteaPlina}
                  style={{ height: '16px', width: '16px', marginRight: '10px' }}
                />
                Salvează orare din bara de navigare și ele vor fi afișate pe această pagină
              </MobileTitle>
              <img
                src={MobileIlustration}
                alt="Ilustrație orar"
                style={{ height: '100%', margin: '0 30px' }}
              />
            </>
          )}
        </MobileContainer>
      </GlobalContainer>
    </>
  );
}

export default PaginaOrareSalvate;
