import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJSON from './en.json';
import roJSON from './ro.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    ro: { ...roJSON },
  },

  lng: 'ro',
  fallbackLng: 'ro',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
