import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerLegenda,
  Culoare,
  LinieLegenda,
  SemnificatieCuloare,
  TitluLegenda,
} from './Legenda.styles';
import Culori from '../../constante/Culori';

function Legenda({ titluLegenda }) {
  const { t } = useTranslation();
  const elementeLegenda = [
    {
      culoare: Culori.seminar,
      semnificatieCuloare: t('seminar'),
    },
    {
      culoare: Culori.laborator,
      semnificatieCuloare: t('laborator'),
    },
    {
      culoare: Culori.curs,
      semnificatieCuloare: t('curs'),
    },
    {
      culoare: Culori.proiect,
      semnificatieCuloare: t('proiect'),
      rotunjireBordura: '10px',
    },
  ];

  return (
    <ContainerLegenda cellSpacing={0}>
      <tbody>
        <tr>
          <TitluLegenda colSpan={2}>{t('legenda')}</TitluLegenda>
        </tr>
        {elementeLegenda.map((elementLegenda) => (
          <LinieLegenda key={elementLegenda.semnificatieCuloare}>
            <Culoare culoare={elementLegenda.culoare} bordura={elementLegenda.rotunjireBordura} />
            <SemnificatieCuloare bordura={elementLegenda.rotunjireBordura}>
              {elementLegenda.semnificatieCuloare}
            </SemnificatieCuloare>
          </LinieLegenda>
        ))}
      </tbody>
    </ContainerLegenda>
  );
}

export default Legenda;
