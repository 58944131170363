import React from 'react';
import {
  CardButton,
  CardButtonsContainer,
  CardContainer,
  CardImage,
  CardSubtitle,
  CardTextContainer,
  CardTitle,
} from './FounderCard.styles';
import MailIcon from '../../../resurse/founders/mail.svg';
import LinkedInIcon from '../../../resurse/founders/linkedin.svg';
import CosminIcon from '../../../resurse/founders/cosmin.png';
import DanielIcon from '../../../resurse/founders/daniel.png';

const FounderCard = ({ data }) => {
  return (
    <CardContainer>
      <CardImage src={data.id === 1 ? CosminIcon : DanielIcon} alt="Avatar" />
      <CardTextContainer>
        <CardTitle>{data.name}</CardTitle>
        <CardSubtitle>{data.role}</CardSubtitle>
      </CardTextContainer>
      <CardButtonsContainer>
        <CardButton
          src={MailIcon}
          alt="Mail"
          onClick={() => {
            const subject = encodeURIComponent('Subiectul Mesajului Aici');
            const body = encodeURIComponent('Corpul mesajului aici.');
            window.location.href = `mailto:${data.email}?subject=${subject}&body=${body}`;
          }}
        />
        <CardButton
          src={LinkedInIcon}
          alt="LinkedIn"
          onClick={() => {
            window.open(data.linkedIn, '_blank');
          }}
        />
      </CardButtonsContainer>
    </CardContainer>
  );
};

export default FounderCard;
