import React, { useState, useEffect } from 'react';
import { replaceDiactritics } from '../../common/utils';
import { ArrowDown, DropdownStilizat } from './Dropdown2.styles';
import DropdownArrow from '../../resurse/dropdownArrow.svg';

function Dropdown2({ options, placeholder, handleSelect }) {
  const [optionIndex, setOptionIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleDropdown = (e) => {
    if (!isOpen) {
      setSearchTerm('');
    }

    setIsOpen(!isOpen);
  };

  function handleKeyDown(e) {
    const visibleOptions = 5; // Number of visible options at a time
    const middleOption = Math.floor(visibleOptions / 2);

    try {
      if (e.keyCode === 38 && optionIndex > 0) {
        // 38 is the keyCode for the up arrow key
        setOptionIndex(optionIndex - 1);
        if (optionIndex - 1 >= middleOption) {
          const option = document.getElementById(`option-${optionIndex - middleOption}`);
          option.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          const option = document.getElementById(`option-0`);
          option.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else if (e.keyCode === 40 && optionIndex < filteredOptions.length - 1) {
        // 40 is the keyCode for the down arrow key
        setOptionIndex(optionIndex + 1);
        if (
          optionIndex + 1 >= middleOption &&
          optionIndex + 1 <= filteredOptions.length - middleOption
        ) {
          const option = document.getElementById(`option-${optionIndex - middleOption + 1}`);
          option.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (optionIndex + 1 > filteredOptions.length - middleOption) {
          const option = document.getElementById(`option-${filteredOptions.length - 1}`);
          option.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      } else if (e.keyCode === 13) {
        // 13 is the keyCode for the enter key
        selectOption(filteredOptions[optionIndex]);
      }
    } catch (e) {}
  }

  const selectOption = (option) => {
    setIsOpen(false);
    setSearchTerm(option?.nume);
    handleSelect(option);
  };

  const handleSearch = (event) => {
    setIsOpen(true);
    setSearchTerm(event.target.value);
  };

  const filteredOptions = options.filter((option) => {
    return replaceDiactritics(option?.nume)
      ?.toLowerCase()
      .includes(replaceDiactritics(searchTerm?.toLowerCase() ?? ''));
  });

  const [latimeCard, setLatimeCard] = useState(0);
  const [inaltimeOptiuni, setInaltimeOptiuni] = useState(0);

  const AutoredimensionareElemente = () => {
    const elementeCard = document.getElementsByClassName('card');
    const latimeCard = elementeCard[0]?.offsetWidth;
    setLatimeCard(latimeCard);
  };

  const SetareInaltimeOptiuni = () => {
    const div = document?.getElementById('dropdown');
    const divPosition = div?.getBoundingClientRect();
    const distanceFromBottom = window.innerHeight - divPosition?.bottom;
    setInaltimeOptiuni(distanceFromBottom - 20);
  };

  useEffect(() => {
    AutoredimensionareElemente();
    SetareInaltimeOptiuni();
  }, []);

  window.addEventListener('resize', () => {
    AutoredimensionareElemente();
    SetareInaltimeOptiuni();
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.getElementById('dropdown');
      if (dropdown && !dropdown.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <DropdownStilizat className="dropdown" id="dropdown">
      <div
        className="selected-option"
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        tabIndex={0} // add this attribute to make the div focusable
        style={{ width: latimeCard }}
      >
        <input
          className="input"
          type="text"
          value={searchTerm ?? ''}
          onFocus={() => setSearchTerm('')}
          onChange={handleSearch}
          placeholder={placeholder}
        />
        <ArrowDown
          src={DropdownArrow}
          alt="Sageata dropdown"
          isOpen={isOpen}
          tabIndex={0} // add this attribute to make the img focusable
          onClick={toggleDropdown}
          onKeyDown={handleKeyDown} // add onKeyDown event listener to the arrow
        />
      </div>
      {isOpen && (
        <div
          className="options"
          style={{
            width: latimeCard,
            maxHeight: inaltimeOptiuni,
            height: 'min-content',
          }}
        >
          {filteredOptions.map((option, index) => (
            <div
              key={option?.id}
              id={`option-${index}`}
              className={`option ${optionIndex === index ? 'selected' : ''}`}
              onClick={() => selectOption(option)}
              onMouseEnter={() => setOptionIndex(index)}
            >
              {option?.nume}
            </div>
          ))}
          {filteredOptions.length === 0 && (
            <div className="option">Nu s-a găsit niciun rezultat</div>
          )}
        </div>
      )}
    </DropdownStilizat>
  );
}

export default Dropdown2;
