export const GET_SALI_REQUEST = 'GET_SALI_REQUEST';
export const GET_SALI_SUCCESS = 'GET_SALI_SUCCESS';
export const GET_SALI_FAILURE = 'GET_SALI_FAILURE';

export function getSaliRequest() {
  return { type: GET_SALI_REQUEST };
}

export function getSaliSuccess(sali) {
  return {
    type: GET_SALI_SUCCESS,
    sali,
  };
}

export function getSaliFailure(error) {
  return { type: GET_SALI_FAILURE, error: error.message };
}

export function getSali() {
  return async (dispatch) => {
    dispatch(getSaliRequest());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sali.php?json`);
      const data = await response.json();
      await dispatch(getSaliSuccess(data));
    } catch (error) {
      dispatch(getSaliFailure(error));
    }
  };
}
