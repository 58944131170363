import React from 'react';
import { NavigationContainer } from './NavigationMenu.styles';
import NavigationButton from './NavigationButton';
import HomeIcon from '../../resurse/iconiteInterfata/home-icon.svg';
import BookmarkIcon from '../../resurse/iconiteInterfata/bookmark.svg';
import InfoIcon from '../../resurse/iconiteInterfata/info-icon.svg';
import { useNavigate } from 'react-router-dom';

function NavigationMenu() {
  const navigate = useNavigate();

  function isIOS() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod');
  }

  return (
    <NavigationContainer isIOS={isIOS()}>
      <NavigationButton icon={HomeIcon} text="Acasă" onClick={() => navigate('/')} isHomeIcon />
      <NavigationButton
        icon={BookmarkIcon}
        text="Salvate"
        onClick={() => navigate('/orare-salvate')}
      />
      <NavigationButton icon={InfoIcon} text="Info" onClick={() => navigate('/info')} />
    </NavigationContainer>
  );
}

export default NavigationMenu;
