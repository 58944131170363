export const replaceDiactritics = (text) => {
  return text
    ?.replace(/ă/g, 'a')
    ?.replace(/Ă/g, 'A')
    ?.replace(/î/g, 'i')
    ?.replace(/Î/g, 'I')
    ?.replace(/â/g, 'a')
    ?.replace(/Â/g, 'A')
    ?.replace(/ș/g, 's')
    ?.replace(/Ș/g, 'S')
    ?.replace(/ş/g, 's')
    ?.replace(/Ş/g, 'S')
    ?.replace(/ț/g, 't')
    ?.replace(/ţ/g, 't')
    ?.replace(/Ț/g, 'T');
};
