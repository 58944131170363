import styled from 'styled-components';
import Culori from '../../constante/Culori';
import DimensiuniText from '../../constante/DimensiuniText';

export const ContainerModal = styled.div`
  position: absolute;
  width: clamp(200px, 100%, 328px);
  top: 58px;
  right: 30px;
  border-radius: 10px;
  background-color: ${Culori.alb};
  z-index: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  margin-top: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  transition: opacity linear 0.2s;
  color: ${Culori.albastru};

  &.visible {
    opacity: 1;
    transform: translateX(-13%);
    z-index: 999;
  }

  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }

  &.hidden {
    opacity: 0;
    transition: opacity linear 0.2s;
    transform: translateX(-13%);
    z-index: -1;
    transition: z-index 0.2s linear;

    a {
      pointer-events: none;
      user-select: none;
    }
  }

  a {
    text-decoration: none;
    color: ${Culori.albastru};
    cursor: pointer;
    font-size: ${DimensiuniText.text4};

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: ${DimensiuniText.text4};
  }

  h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const SageataContainer = styled.div`
  position: absolute;
  top: -9px;
  right: 22%;
  width: 18px;
  height: 18px;
  background-color: white;
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.04);
  border: none;
  outline: none;
  transform: rotate(135deg);
`;

// Modal item
export const ItemContainer = styled.button`
  height: 34px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  border: none;
  outline: none;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: ${Culori.alb};
  color: ${Culori.albastru};
  cursor: pointer;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: scale(0.98);
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.18);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 100%;
  background-color: ${Culori.albastru};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const Icon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ItemText = styled.p`
  font-size: ${DimensiuniText.text4};
`;
