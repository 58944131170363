import React from 'react';
import { ContainerCard, Iconita, Text } from './CardFacultate.styles';

function CardFacultate({ text, iconita, onClick }) {
  return (
    <ContainerCard onClick={onClick}>
      <Text>{text}</Text>
      <Iconita className="iconitaFacultate" src={iconita} />
    </ContainerCard>
  );
}

export default CardFacultate;
