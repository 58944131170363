import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ContainerCarduriFacultati,
  GlobalContainer,
  MobileContainer,
  MobileInfoContainer,
  MobileTitle,
  Selector,
} from './PaginaAcasa.styles';
import FIESC from '../../resurse/iconiteFacultati/iconitaFiesc.svg';
import FS from '../../resurse/iconiteFacultati/iconitaFs.svg';
import FEFS from '../../resurse/iconiteFacultati/iconitaFefs.svg';
import FSE from '../../resurse/iconiteFacultati/iconitaFse.svg';
import FDSA from '../../resurse/iconiteFacultati/iconitaFdsa.svg';
import FIMAR from '../../resurse/iconiteFacultati/iconitaFimar.svg';
import FEAA from '../../resurse/iconiteFacultati/iconitaFeaa.svg';
import Depart from '../../resurse/iconiteFacultati/iconitaDspp.svg';
import FMSB from '../../resurse/iconiteFacultati/iconitaFmsb.svg';
import FIG from '../../resurse/iconiteFacultati/iconitaFig.svg';
import FLSC from '../../resurse/iconiteFacultati/iconitaFlsc.svg';
import FIA from '../../resurse/iconiteFacultati/iconitaFia.svg';
import CSUD from '../../resurse/iconiteFacultati/iconitaCSUD.svg';
import CardFacultate from '../../componente/cardFacultate/CardFacultate';
import Antet from '../../componente/antet/Antet';
import { useNavigate } from 'react-router-dom';
import Dropdown2 from '../../componente/dropdown2/Dropdown2';
import Spinner from '../../componente/spinner/Spinner';
import InfoCard from '../../componente/mobile/info-card/InfoCard';
import PeopleIcon from '../../resurse/iconiteInterfata/people.svg';
import PersonIcon from '../../resurse/iconiteInterfata/person.svg';
import PeopleBlueIcon from '../../resurse/iconiteInterfata/people-blue.svg';
import PersonBlueIcon from '../../resurse/iconiteInterfata/person-blue.svg';
import ScheduleIcon from '../../resurse/iconiteInterfata/schedule.svg';
import ScheduleIconWhite from '../../resurse/iconiteInterfata/schedule-white.svg';
import Dropdown from '../../componente/mobile/dropdown/Dropdown';
import Switch from '../../componente/mobile/switch/Switch';
import SeeAllItemsIcon from '../../resurse/iconiteInterfata/seeAll.svg';
import NavigationBack from '../../resurse/iconiteInterfata/navigation-back.svg';

function PaginaAcasa() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { cadreDidactice } = useSelector((state) => state.cadreDidactice);
  const { facultati } = useSelector((state) => state.facultati);
  const { sali } = useSelector((state) => state.sali);

  const [activateTransition, setActivateTransition] = useState(false);
  const [isContentHidden, setIsContentHidden] = useState(true);

  const [comutareSwitch, setComutareSwitch] = useState(1);
  const [listaCadreDidactice, setListaCadreDidactice] = useState([]);
  const [listaSali, setListaSali] = useState([]);
  const [orareSalvate, setOrareSalvate] = useState([]);

  const facultatiObject = {
    FIESC: { iconita: FIESC, nume: t('FIESC') },
    FS: { iconita: FS, nume: t('FS') },
    FEFS: { iconita: FEFS, nume: t('FEFS') },
    FSE: { iconita: FSE, nume: t('FSE') },
    FDSA: { iconita: FDSA, nume: t('FDSA') },
    FIMAR: { iconita: FIMAR, nume: t('FIMAR') },
    FEAA: { iconita: FEAA, nume: t('FEAA') },
    Depart: { iconita: Depart, nume: t('Depart') },
    FMSB: { iconita: FMSB, nume: t('FMSB') },
    FIG: { iconita: FIG, nume: t('FIG') },
    FLSC: { iconita: FLSC, nume: t('FLSC') },
    FIA: { iconita: FIA, nume: t('FIA') },
    CSUD: { iconita: CSUD, nume: t('CSUD') },
  };

  const AutoredimensionareElemente = () => {
    const elementeCard = document.getElementsByClassName('card');
    const elementeSwitch = document.getElementsByClassName('switch');

    const latimeCard = elementeCard[0]?.offsetWidth;
    for (let i = elementeCard.length - 3; i < elementeCard.length; i++) {
      elementeCard[i] && (elementeCard[i].style.maxWidth = `${latimeCard}px`);
    }
    for (let i = 0; i < elementeSwitch.length; i++) {
      elementeCard[i] && (elementeSwitch[i].style.maxWidth = `${latimeCard}px`);
      elementeCard[i] && (elementeSwitch[i].style.width = `${latimeCard}px`);
    }
  };

  const handleSelectProfesor = async (option) => {
    option?.id && navigate(`/profesori/${option.id}`);
  };

  const handleSelectSala = async (option) => {
    option?.id && navigate(`/sali/${option.id}`);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      AutoredimensionareElemente();
    });

    return () => window.removeEventListener('resize', AutoredimensionareElemente);
  }, [facultati]);

  const sortareCadreDidactice = (cadreDidactice) => {
    if (cadreDidactice.length === 0) return;
    let cadreDidacticeCopy = [...cadreDidactice]; // creează o copie
    cadreDidacticeCopy?.sort((a, b) => {
      const lastNameComparison = a.lastName.localeCompare(b.lastName);
      if (lastNameComparison !== 0) return lastNameComparison;
      return a.firstName.localeCompare(b.firstName);
    });

    setListaCadreDidactice(cadreDidacticeCopy?.slice(2, cadreDidacticeCopy?.length)); // the first 2 items are empty
  };

  const sortareSali = async (sali) => {
    if (sali.length === 0) return;
    let saliCopy = [...sali]; // creează o copie
    saliCopy.sort((a, b) => {
      if (a.buildingName < b.buildingName) {
        return -1;
      }
      if (a.buildingName > b.buildingName) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setListaSali(saliCopy.slice(1, saliCopy.length));
  };

  useEffect(() => {
    sortareCadreDidactice([...cadreDidactice]);
    if (sali.length > 0) sortareSali(sali);
  }, [cadreDidactice, sali]);

  useEffect(() => {
    AutoredimensionareElemente();
    setIsContentHidden(false);
  }, [facultati]);

  useEffect(() => {
    if (localStorage.getItem('orareSalvate')) {
      setOrareSalvate(JSON.parse(localStorage.getItem('orareSalvate')));
    }
  }, []);

  return (
    <>
      <Antet />
      {/* WEB */}
      <GlobalContainer>
        <ContainerCarduriFacultati>
          {facultati.length ? (
            <Selector className="selector">
              <Switch
                options={[t('profesori'), t('sali')]}
                setSelectedOption={setComutareSwitch}
                selectedOption={comutareSwitch}
                activateTransition={true}
                hidden={true}
              />
              <Dropdown2
                className="switch"
                placeholder={comutareSwitch === 1 ? t('selectareProfesor') : t('selectareSala')}
                handleSelect={comutareSwitch === 1 ? handleSelectProfesor : handleSelectSala}
                options={
                  comutareSwitch === 1
                    ? listaCadreDidactice.map((profesor) => {
                        return {
                          id: profesor.id,
                          nume: profesor?.lastName + ' ' + profesor?.firstName,
                        };
                      })
                    : listaSali.map((sala) => {
                        return {
                          id: sala.id,
                          nume: `${sala?.buildingName ? `Corp ${sala?.buildingName}` : ''}${
                            sala?.name && sala?.buildingName ? ' - ' : ''
                          }${sala?.name}`,
                        };
                      })
                }
              />
            </Selector>
          ) : (
            <Spinner isSpinning={true} />
          )}
          {facultati.slice(1).map((facultate, index) => {
            const facultateObject = facultatiObject[facultate.shortName.replace('.', '')] || {
              name: facultate.shortName || '',
              iconita: ''
            };

            return (
            <CardFacultate
              className="card"
              key={index}
              text={facultateObject.nume}
              iconita={facultateObject.iconita}
              onClick={() =>
                navigate(`/facultate/${facultate?.id}`, {
                  state: {
                    id: facultate.shortName,
                    denumireFacultate: facultate.longName,
                  },
                })
              }
            />
          )})}
        </ContainerCarduriFacultati>
        {/* Mobile */}
        <MobileContainer activateTransition={activateTransition} hidden={isContentHidden}>
          <a href="http://orar.usv.ro/orar">
            <img src={NavigationBack} alt="Spre pagina clasică" className="navigation-back" />
            Spre interfața clasică
          </a>
          <MobileTitle>{t('Categorii')}</MobileTitle>
          <Dropdown
            icon={PeopleBlueIcon}
            text={'Orare semigrupe'}
            onClick={() => {
              setActivateTransition(!activateTransition);
              setTimeout(() => {
                navigate('/facultate');
              }, 300);
            }}
          />
          <Dropdown
            icon={PersonBlueIcon}
            text={'Orare profesori'}
            onClick={() => {
              setActivateTransition(!activateTransition);
              setTimeout(() => {
                navigate('/profesori');
              }, 300);
            }}
          />
          <Dropdown
            icon={ScheduleIcon}
            text={'Orare săli'}
            onClick={() => {
              setActivateTransition(!activateTransition);
              setTimeout(() => {
                navigate('/sali');
              }, 300);
            }}
          />
          {orareSalvate.length > 0 && (
            <>
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                <MobileTitle>{t('Orarele mele')}</MobileTitle>
                {orareSalvate.length > 5 && (
                  <img
                    src={SeeAllItemsIcon}
                    alt="schedule"
                    style={{
                      width: '16px',
                      height: '16px',
                      resizeMode: 'contain',
                      marginRight: '8px',
                    }}
                    onClick={() => {
                      setActivateTransition(!activateTransition);
                      setTimeout(() => {
                        navigate('/orare-salvate');
                      }, 300);
                    }}
                  />
                )}
              </div>
              <MobileInfoContainer>
                {orareSalvate.slice(0, 5).map((orar, index) => {
                  return (
                    <InfoCard
                      key={index}
                      icon={
                        orar.tip === 'semigrupe'
                          ? PeopleIcon
                          : orar.tip === 'profesori'
                          ? PersonIcon
                          : ScheduleIconWhite
                      }
                      text={orar.titlu}
                      onClick={() => {
                        setActivateTransition(!activateTransition);
                        setTimeout(() => {
                          navigate(orar.cale);
                        }, 300);
                      }}
                    />
                  );
                })}
              </MobileInfoContainer>
            </>
          )}
        </MobileContainer>
      </GlobalContainer>
    </>
  );
}

export default PaginaAcasa;
