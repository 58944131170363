import styled from 'styled-components';
import Culori from '../../constante/Culori';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-left: 26px;
  padding-right: 26px;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: all 0.3s ease-in-out;

  @media (max-width: 834px) {
    display: none;
  }
`;

export const InfoTitle = styled.h1`
  font-size: 20px;
  color: ${Culori.albastru};
  margin: 0;
  width: 100%;
`;

export const InfoText = styled.h2`
  font-size: 14px;
  text-align: justify;
  text-indent: 40px;
  color: ${Culori.albastru};
  margin: 5px 0 20px 0;
  font-weight: 400;
`;

export const DivSpace = styled.div`
  height: 100px;
  width: 100%;

  @media (max-width: 834px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Culori.albastru};
  margin-top: 20px;
`;
