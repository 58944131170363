import React, { useEffect, useState } from 'react';
import Antet from '../../componente/antet/Antet';
import { DivSpace, InfoContainer, InfoText, InfoTitle, Title } from './PaginInformatii.styles';
import { GlobalContainer, MobileContainer } from '../paginaAcasa/PaginaAcasa.styles';
import Dropdown from '../../componente/mobile/dropdown/Dropdown';
import FounderCard from '../../componente/mobile/founder-card/FounderCard';

function PaginaInformatii() {
  const [isContentHidden, setIsContentHidden] = useState(true);

  const mockButtonsData = [
    {
      id: 1,
      text: 'Cum se interpretează paritatea?',
      moreInfo: `Unele activități din orar se desfășoară doar în săptămânile impare (1,3,5,etc) și de
      aceea ele sunt afișate deasupra unei linii orizontale albastre. Alte activități se
      desfășoară doar în săptămânile pare (2,4,6,etc) și de aceea ele sunt afișate sub
      respectiva linie. Există și situația în care activitatea se desfășoară săptămânal, caz
      în care nu se afișează nicio linie orizontală albastră. De asemenea, există și situația
      în care există activitate și în săptămânile impare (deasupra liniei) și în săptămânile
      pare (sub linie).`,
    },
    {
      id: 2,
      text: 'Cum se află mai multe informații referitoare la orare?',
      moreInfo: `Ducând cursorul mouse-ului deasupra unei activități, este afișată o descriere detaliată
      a acesteia, care vine să completeze informațiile prezentate inițial. Aceeași tehnică se
      poate utiliza și pentru a obține descrierea specializării în cazul orarelor pe ani de
      studiu.`,
    },
    {
      id: 3,
      text: 'Cum se salvează un orar?',
      moreInfo: `Pentru a salva un orar, trebuie să apăsați pe butonul de salvare din partea dreaptă
      sus-jos a paginii. După ce ați apăsat pe buton, trebuie să completați numele orarului și
      să apăsați pe butonul de salvare din fereastra care se deschide. Orarul salvat poate fi
      accesat din meniul de navigare, la fel ca și orarele salvate anterior.`,
    },
    {
      id: 4,
      text: 'Cum se instalează pe Android?',
      moreInfo: `Pentru a instala aplicația pe un dispozitiv Android, trebuie să apăsați pe butonul de
      instalare care apare în partea de jos sau de sus a ecranului după ce ați deschis
      aplicația. Dacă nu apare butonul, trebuie să accesați meniul browser-ului și să apăsați
      pe "Adaugă la ecranul principal".`,
    },
    {
      id: 5,
      text: 'Cum se instalează pe iOS?',
      moreInfo: `Pentru a instala aplicația pe un dispozitiv iOS, trebuie să accesați aplicația din
      Safari și să apăsați pe butonul de instalare care apare în partea de jos sau de sus a
      ecranului. După ce ați apăsat pe buton, trebuie să apăsați pe "Adaugă la ecranul
      principal". În cazul în care nu apare butonul de instalare, trebuie să apasați pe
      butonul de "Share" și să apăsați pe "Adaugă la ecranul principal". Dacă nu apare butonul
      de "Share", trebuie să accesați meniul browser-ului și să apăsați pe "Adaugă la ecranul
      principal".`,
    },
  ];

  const mockFoundersData = [
    {
      id: 1,
      name: 'Cosmin Pascariu',
      role: 'Frontend & Mobile Developer',
      email: 'cosmin.pascariu.cp@gmail.com',
      linkedIn: 'https://www.linkedin.com/in/cosmin-mihai-pascariu/',
    },
    {
      id: 2,
      name: 'Daniel Lupaștean',
      role: 'Backend Developer & Designer',
      email: 'lupasteandaniell1@gmail.com',
      gmailUserId: 'CllgCJfnZqTGbSvkGScqrrlgcmVSNRZHKjpcVmPhkVXGmCrFFkmrhKCfkqLgXDmRlXvRHvzDKdV',
      linkedIn: 'https://www.linkedin.com/in/daniellupastean/',
    },
  ];

  useEffect(() => {
    setIsContentHidden(false);
  }, []);

  return (
    <>
      <Antet title={'Informații'} />
      <GlobalContainer>
        {/* Desktop */}
        <InfoContainer hidden={isContentHidden}>
          <InfoTitle>1. Cum se interpretează paritatea?</InfoTitle>
          <InfoText>
            Unele activități din orar se desfășoară doar în săptămânile impare (1,3,5,etc) și de
            aceea ele sunt afișate deasupra unei linii orizontale albastre. Alte activități se
            desfășoară doar în săptămânile pare (2,4,6,etc) și de aceea ele sunt afișate sub
            respectiva linie. Există și situația în care activitatea se desfășoară săptămânal, caz
            în care nu se afișează nicio linie orizontală albastră. De asemenea, există și situația
            în care există activitate și în săptămânile impare (deasupra liniei) și în săptămânile
            pare (sub linie).
          </InfoText>
          <InfoTitle>2. Cum se află informații suplimentare referitoare la orare?</InfoTitle>
          <InfoText>
            Ducând cursorul mouse-ului deasupra unei activități, este afișată o descriere detaliată
            a acesteia, care vine să completeze informațiile prezentate inițial. Aceeași tehnică se
            poate utiliza și pentru a obține descrierea specializării în cazul orarelor pe ani de
            studiu.
          </InfoText>
          <InfoTitle>3. Cum se salvează un orar?</InfoTitle>
          <InfoText>
            Pentru a salva un orar, trebuie să apăsați pe butonul de salvare din partea dreaptă
            sus-jos a paginii. După ce ați apăsat pe buton, trebuie să completați numele orarului și
            să apăsați pe butonul de salvare din fereastra care se deschide. Orarul salvat poate fi
            accesat din meniul de navigare, la fel ca și orarele salvate anterior.
          </InfoText>
          <InfoTitle>4. Cum se instalează aplicația?</InfoTitle>
          <InfoTitle>Android</InfoTitle>
          <InfoText>
            Pentru a instala aplicația pe un dispozitiv Android, trebuie să apăsați pe butonul de
            instalare care apare în partea de jos sau de sus a ecranului după ce ați deschis
            aplicația. Dacă nu apare butonul, trebuie să accesați meniul browser-ului și să apăsați
            pe "Adaugă la ecranul principal".
          </InfoText>
          <InfoTitle>iOS</InfoTitle>
          <InfoText>
            Pentru a instala aplicația pe un dispozitiv iOS, trebuie să accesați aplicația din
            Safari și să apăsați pe butonul de instalare care apare în partea de jos sau de sus a
            ecranului. După ce ați apăsat pe buton, trebuie să apăsați pe "Adaugă la ecranul
            principal". În cazul în care nu apare butonul de instalare, trebuie să apasați pe
            butonul de "Share" și să apăsați pe "Adaugă la ecranul principal". Dacă nu apare butonul
            de "Share", trebuie să accesați meniul browser-ului și să apăsați pe "Adaugă la ecranul
            principal".
          </InfoText>
        </InfoContainer>
        <DivSpace />
        {/* Mobile */}
        <MobileContainer hidden={isContentHidden}>
          {mockButtonsData.map((button) => (
            <Dropdown key={button.id} text={button.text} moreInfo={button.moreInfo} />
          ))}
          <Title>Aplicație dezvoltată de:</Title>
          {mockFoundersData.map((founder) => (
            <FounderCard key={founder.id} data={founder} />
          ))}
        </MobileContainer>
      </GlobalContainer>
    </>
  );
}

export default PaginaInformatii;
