import React from 'react';
import { ContainerModal, SageataContainer } from './ModalOrareSalvate.styles';
import { useTranslation } from 'react-i18next';
import ModalItem from './ModalItem';
import PeopleIcon from '../../resurse/iconiteInterfata/people.svg';
import PersonIcon from '../../resurse/iconiteInterfata/person.svg';
import ScheduleIconWhite from '../../resurse/iconiteInterfata/schedule-white.svg';
import { useNavigate } from 'react-router-dom';

function ModalOrareSalvate({ classname, orareSalvate }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ContainerModal className={classname} id="esteOrar">
      <SageataContainer />
      <div className="container">
        <h1>Orare salvate:</h1>
        {orareSalvate.length > 0 ? (
          orareSalvate.map((orar, index) => (
            <ModalItem
              key={index}
              icon={
                orar.tip === 'semigrupe'
                  ? PeopleIcon
                  : orar.tip === 'profesori'
                  ? PersonIcon
                  : ScheduleIconWhite
              }
              text={orar.titlu}
              onClick={() => navigate(orar.cale)}
            />
          ))
        ) : (
          <p>{t('nuExistaOrarSalvat')}</p>
        )}
      </div>
    </ContainerModal>
  );
}

export default ModalOrareSalvate;
