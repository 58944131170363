import styled from 'styled-components';
import Culori from '../../constante/Culori';
import DimensiuniText from '../../constante/DimensiuniText';

export const ContainerCard = styled.div.attrs(() => ({ className: 'card' }))`
  display: flex;
  width: 350px;
  flex-grow: 1;
  height: 95px;
  background-color: ${Culori.alb};
  border-radius: 10px;
  // border: 2px solid ${Culori.bordura};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 12.5px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);

    .iconitaFacultate {
      transform: scale(1.1) rotate(3deg);
      transition: transform 0.2s linear;
    }
  }

  @media (max-width: 834px) {
    border: none;
    outline: none;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

    &:active {
      opacity: 0.5;
    }
  }
`;

export const Text = styled.p`
  font-size: ${DimensiuniText.text4};
  line-height: 24px;
  margin: 0 10px;
  color: ${Culori.albastru};
  text-align: left;
  display: flex;
  flex-grow: 1;
  font-weight: 400;

  @media (max-width: 834px) {
    font-size: 16px;
  }
`;

export const Iconita = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;
