import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ButonInterfata from '../butonInterfata/ButonInterfata';
import ModalOrareSalvate from '../modalOrareSalvate/ModalOrareSalvate';
import {
  ContainerAntet,
  ContainerTitluPagina,
  SubtitluPagina,
  TitluPagina,
  MeniuMobile,
  ContainerButoaneInterfata,
} from './Antet.styles';

import UsvLogo from '../../resurse/sigla/sigla.svg';
import Stea from '../../resurse/iconiteInterfata/iconitaStea.svg';
import SteaPlina from '../../resurse/iconiteInterfata/iconitaSteaPlina.svg';
import Informatii from '../../resurse/iconiteInterfata/info-icon.svg';
import BookmarkIcon from '../../resurse/iconiteInterfata/bookmark.svg';
import NavigationMenu from '../navigationMenu/NavigationMenu';
import ArrowBack from '../../resurse/iconiteInterfata/arrow-back.svg';
import NavigationBack from '../../resurse/iconiteInterfata/navigation-back.svg';

function Antet({ showArrow, title }) {
  const { subgrupe } = useSelector((state) => state.subgrupe);
  const { cadreDidactice } = useSelector((state) => state.cadreDidactice);
  const { sali } = useSelector((state) => state.sali);
  const { currentWeek } = useSelector((state) => state.orar);

  const navigate = useNavigate();
  const location = useLocation();
  const [afisareMeniu, setAfisareMeniu] = useState(false);
  const [afisareModal, setAfisareModal] = useState(false);
  const [profesor, setProfesor] = useState({});
  const [sala, setSala] = useState({});

  const [semigrupa, setSemigrupa] = useState({});

  const [orareSalvate, setOrareSalvate] = useState(
    localStorage.getItem('orareSalvate') ? JSON.parse(localStorage.getItem('orareSalvate')) : [],
  );
  const { idSemigrupa, idProfesor, idSala } = useParams();
  const { t } = useTranslation();

  const handleClickOutside = (event) => {
    const isModal = document.getElementById('esteOrar')?.contains(event.target);
    const isButton = document.getElementById('esteButonInterfata')?.contains(event.target);
    const isVisible = document.getElementsByClassName('visible').length > 0;
    if (isVisible && !isModal) {
      setAfisareModal(false);
    } else if (isButton) {
      setAfisareModal(!afisareModal);
    }
  };
  const handleResize = () => {
    if (window.innerWidth > 834) {
      setAfisareMeniu(false);
    }
  };
  const handleSaveOrar = () => {
    if (idSemigrupa === undefined && idProfesor === undefined && idSala === undefined) return;
    if (existaOrarSalvat()) {
      // sterg orarul din lista
      setOrareSalvate([
        ...orareSalvate?.filter((orar) => orar.id !== (idProfesor || idSemigrupa || idSala)),
      ]);
      localStorage.setItem(
        'orareSalvate',
        JSON.stringify([
          ...orareSalvate?.filter((orar) => orar.id !== (idProfesor || idSemigrupa || idSala)),
        ]),
      );
    } else {
      // adaug orarul in lista
      const orarulNou = {
        tip: idProfesor ? 'profesori' : idSala ? 'săli' : 'semigrupe',
        id: idProfesor ? idProfesor : idSala ? idSala : idSemigrupa,
        cale: location.pathname,
        titlu: idSemigrupa
          ? semigrupa.specializationShortName +
            '  • an ' +
            semigrupa.studyYear +
            '  •  ' +
            semigrupa.groupName +
            semigrupa.subgroupIndex
          : idProfesor
          ? profesor.lastName + ' ' + profesor.firstName
          : sala?.buildingName
          ? 'Corp '
          : '' + sala?.buildingName + (sala?.buildingName && sala?.name ? ' - ' : '') + sala?.name,
      };
      setOrareSalvate([...orareSalvate, orarulNou]);
      localStorage.setItem('orareSalvate', JSON.stringify([...orareSalvate, orarulNou]));
    }
  };

  const existaOrarSalvat = () => {
    if (
      orareSalvate.filter((orar) => {
        return orar?.id === idProfesor && orar?.tip === 'profesori';
      }).length > 0
    )
      return true;
    if (
      orareSalvate.filter((orar) => {
        return orar.id === idSala && orar?.tip === 'săli';
      }).length > 0
    )
      return true;
    if (
      orareSalvate.filter((orar) => {
        return orar.id === idSemigrupa && orar.tip === 'semigrupe';
      }).length > 0
    )
      return true;
    return false;
  };

  function handleBackButtonClick() {
    if (location.pathname.includes('semigrupa') || location.pathname.includes('facultate/')) {
      navigate('/facultate');
    } else {
      navigate('/');
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [location.pathname]);

  // extrage datele despre profesor și semigrupă pentru a fi salvate în local storage
  useEffect(() => {
    setSemigrupa(
      idSemigrupa ? subgrupe.filter((semigrupa) => semigrupa.id === idSemigrupa)[0] : null,
    );
    setProfesor(
      idProfesor ? cadreDidactice.filter((profesor) => profesor.id === idProfesor)[0] : null,
    );
    setSala(idSala ? sali.filter((sala) => sala.id === idSala)[0] : null);
  }, [subgrupe, cadreDidactice, location.pathname, idProfesor, idSemigrupa, idSala, sali]);

  return (
    <>
      <ContainerAntet>
        {/* Antet stanga */}
        {showArrow && window.innerWidth < 834 ? (
          <img
            src={ArrowBack}
            alt="arrow back"
            className="back-arrow"
            onClick={handleBackButtonClick}
          />
        ) : (
          <div className="leftContainer">
            <img src={UsvLogo} alt="sigla" className="sigla" onClick={() => navigate('/')} />
            <a href="http://orar.usv.ro/orar">
              <img src={NavigationBack} alt="Spre pagina clasică" className="navigation-back" />
              Spre interfața clasică
            </a>
          </div>
        )}
        {/* Titlul din centru */}
        <ContainerTitluPagina className={afisareMeniu ? 'hidden' : ''}>
          {title ? (
            <TitluPagina>{title}</TitluPagina>
          ) : (
            currentWeek && (
              <>
                <TitluPagina>{`${t('orar')} ${currentWeek[0]?.an}`}</TitluPagina>
                <SubtitluPagina>{`${t('semestru')} ${currentWeek[0]?.sem}  ${
                  currentWeek[0]?.sapt !== '-' ? t('saptamana') : ''
                } ${currentWeek[0]?.sapt !== '-' ? currentWeek[0]?.sapt : ''}`}</SubtitluPagina>
              </>
            )
          )}
        </ContainerTitluPagina>
        {/* butoane antet */}
        <ContainerButoaneInterfata>
          {idSemigrupa || idProfesor || idSala ? (
            <ButonInterfata
              iconita={existaOrarSalvat() ? SteaPlina : Stea}
              onClick={handleSaveOrar}
            />
          ) : (
            window.innerWidth < 834 && <div style={{ width: '50px' }} />
          )}
          <MeniuMobile>
            <ModalOrareSalvate
              classname={afisareModal ? 'visible' : 'hidden'}
              orareSalvate={orareSalvate}
            />
            <ButonInterfata iconita={BookmarkIcon} id="esteButonInterfata" haveID />
            <ButonInterfata iconita={Informatii} onClick={() => navigate('/info')} />
          </MeniuMobile>
        </ContainerButoaneInterfata>
      </ContainerAntet>
      <NavigationMenu />
    </>
  );
}

export default Antet;
