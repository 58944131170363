import React from 'react';
import {
  NavigationButtonText,
  NavigationButtonIcon,
  NavigationButtonContainer,
} from './NavigationMenu.styles';

function NavigationButton({ icon, text, onClick, isHomeIcon }) {
  return (
    <NavigationButtonContainer onClick={onClick}>
      <NavigationButtonIcon src={icon} isHomeIcon={isHomeIcon} />
      <NavigationButtonText>{text}</NavigationButtonText>
    </NavigationButtonContainer>
  );
}

export default NavigationButton;
