import React, { useState } from 'react';
import DropdownArrow from '../../resurse/dropdownArrow.svg';
import {
  ButtonContainer,
  Option,
  OptionsContainer,
  SelectedValueHeader,
  Text,
} from './MobileDropdown.styles';

function MobileDropdown({ placeholder, handleSelect, options }) {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [selectedOptionName, setSelectedOptionName] = useState('');

  const toggleVisibility = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <ButtonContainer>
      <Text>{selectedOptionName.length > 0 ? selectedOptionName : placeholder}</Text>
      <img
        className="arrowDown"
        src={DropdownArrow}
        alt="Sageata dropdown"
        onClick={toggleVisibility}
        style={{ transform: 'rotate(-90deg)', marginRight: 10 }}
      />
      {optionsVisible && (
        <OptionsContainer isOpen={optionsVisible}>
          <SelectedValueHeader>
            <Text>{selectedOptionName.length > 0 ? selectedOptionName : placeholder}</Text>
            <img
              className="arrowLeft"
              src={DropdownArrow}
              alt="Sageata dropdown"
              onClick={() => setOptionsVisible(false)}
              style={{ transform: 'rotate(90deg)', marginRight: 20 }}
            />
          </SelectedValueHeader>
          {options.map((option) => (
            <Option
              className="option"
              key={option.id}
              onClick={() => {
                setSelectedOptionName(option.nume);
                setOptionsVisible(false);
                handleSelect(option);
              }}
            >
              {option.nume}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </ButtonContainer>
  );
}

export default MobileDropdown;
