import React, { useState, useEffect, useRef } from 'react';
import {
  DropdownArrow,
  DropdownContainer,
  DropdownIcon,
  DropdownText,
  MoreInfo,
} from './Dropdown.Styles';
import ArrowIcon from '../../../resurse/iconiteInterfata/arrow.svg';

function Dropdown({ icon, text, onClick, moreInfo }) {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null); // Use this ref for the whole dropdown

  const handleOnClick = () => {
    setShow(!show);
    if (!moreInfo) {
      onClick();
    }
  };

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    // Adding click event listener when the dropdown is mounted
    document.addEventListener('click', closeDropdown);
    return () => {
      // Cleaning up the event listener when the dropdown unmounts
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  return (
    <>
      <DropdownContainer ref={dropdownRef} onClick={handleOnClick}>
        {icon && <DropdownIcon src={icon} />}
        <DropdownText>{text}</DropdownText>
        <DropdownArrow src={ArrowIcon} alt="arrow" open={show} />
      </DropdownContainer>
      {show && moreInfo && (
        <MoreInfo show>
          <p>{moreInfo}</p>
        </MoreInfo>
      )}
    </>
  );
}

export default Dropdown;
