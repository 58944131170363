export const FACULTATE = [
  'Facultatea de Inginerie Electrică şi Ştiinţa Calculatoarelor',
  'Facultatea de Inginerie Mecanică, Autovehicule şi Robotică',
  'Facultatea de Educaţie Fizică şi Sport',
  'Facultatea de Inginerie Alimentară',
  'Facultatea de Istorie şi Geografie',
  'Facultatea de Litere şi Ştiinţe ale Comunicării',
  'Facultatea de Silvicultură',
  'Facultatea de Economie, Administrație și Afaceri',
  'Facultatea de Ştiinţe ale Educaţiei',
  'Facultatea de Drept şi Ştiinţe Administrative',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'Departamentul pentru Pregătirea Personalului Didactic', //20
  'Facultatea de Medicină și Științe Biologice', //21
  'CSUD', // 22
];
