import React from 'react';
import { ContainerButon, IconitaButon } from './ButonInterfata.styles';

function ButonInterfata({ iconita, onClick, classname, haveID }) {
  return (
    <ContainerButon onClick={onClick} id={haveID ? 'esteButonInterfata' : ''}>
      <IconitaButon src={iconita} className={classname} id={haveID ? 'esteButonInterfata' : ''} />
    </ContainerButon>
  );
}

export default ButonInterfata;
