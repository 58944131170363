import styled from 'styled-components';
import Culori from '../../../constante/Culori';

export const CardContainer = styled.button`
  display: flex;
  padding: 10px 19px 10px 0px;
  align-items: center;
  height: 50px;
  width: 100%;
  gap: 16px;
  border-radius: 10px;
  background-color: ${Culori.alb};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border: none;
  outline: none;

  &:active {
    opacity: 0.5;
  }
`;

export const CardIconBackground = styled.div`
  width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
  background-color: ${Culori.albastru};
`;

export const CardIcon = styled.img`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;

export const CardText = styled.p`
  color: ${Culori.albastru};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CardArrow = styled.img`
  margin-left: auto;
`;

export const DeleteButton = styled.button`
  position: absolute;
  width: ${(props) => props.width};
  height: 50px;
  top: 0%;
  right: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fa8a71;
  border: none;
  cursor: pointer;
  opacity: ${(props) => (props.showDeleteButton ? 1 : 0)};
  transition: width 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

export const DeleteButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`;
