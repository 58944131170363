import styled from 'styled-components';
import Culori from '../../../constante/Culori';

export const SearchInputContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  background-color: ${Culori.alb};
  padding: 0 16px;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

  display: ${({ activateTransition }) => (activateTransition ? 'flex' : 'none')};
  opacity: ${({ hidden }) => (hidden ? 1 : 1)};
  transform: ${({ activateTransition }) =>
    activateTransition ? 'translateY(0)' : 'translateY(110%)'};
  transition: all 0.3s ease-in-out;
`;

export const SearchInputField = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0 16px;
  font-size: 16px;
  color: ${Culori.albastru};

  &::placeholder {
    color: ${Culori.placeholder};
  }
`;

export const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;
