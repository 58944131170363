export const GET_CADRE_DIDACTICE_REQUEST = 'GET_CADRE_DIDACTICE_REQUEST';
export const GET_CADRE_DIDACTICE_SUCCESS = 'GET_CADRE_DIDACTICE_SUCCESS';
export const GET_CADRE_DIDACTICE_FAILURE = 'GET_CADRE_DIDACTICE_FAILURE';

export function getCadreDidacticeRequest() {
  return { type: GET_CADRE_DIDACTICE_REQUEST };
}

export function getCadreDidacticeSuccess(cadreDidactice) {
  return { type: GET_CADRE_DIDACTICE_SUCCESS, cadreDidactice };
}

export function getCadreDidacticeFailure(error) {
  console.log(error);
  return { type: GET_CADRE_DIDACTICE_FAILURE, error: error.toString() };
}

export function getCadreDidactice() {
  return async (dispatch) => {
    dispatch(getCadreDidacticeRequest());
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cadre.php?json`);
      const data = await response.json();
      dispatch(getCadreDidacticeSuccess(data));
    } catch (error) {
      console.warn('getCadreDidactice error', error.message);
      dispatch(getCadreDidacticeFailure(error));
    }
  };
}
