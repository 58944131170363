import styled from 'styled-components';
import Culori from '../../../constante/Culori';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 85px;
  padding: 14px;
  border-radius: 10px;
  background-color: ${Culori.alb};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

export const CardImage = styled.img`
  width: 57px;
  height: 57px;
  border-radius: 50%;
  object-fit: cover;

  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 28px;
  width: 100%;
`;

export const CardTitle = styled.h3`
  font-size: 16px;
  color: ${Culori.albastru};
  margin: 0;

  @media (max-width: 350px) {
    font-size: 13px;
  }
`;

export const CardSubtitle = styled.p`
  font-size: 14px;
  color: ${Culori.albastru};
  margin: 0;

  @media (max-width: 350px) {
    font-size: 10px;
  }
`;

export const CardButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const CardButton = styled.img`
  width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
`;
