import styled from 'styled-components';
import Culori from '../../constante/Culori';

// Web styles
export const ContainerTitluPagina = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitluPagina = styled.h1`
  font-size: 36px;
  line-height: 64px;
  text-align: center;
  color: ${Culori.albastru};
  margin: 0;
`;

export const SubtitluPagina = styled.h2`
  font-size: 22px;
  text-align: center;
  color: ${Culori.albastru};
  margin: 0;
`;

export const ContainerButoaneInterfata = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

// make this div wrap on third element
export const ContainerCarduriFacultati = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 0 0 16px 0;

  @media (max-width: 834px) {
    display: none;
  }
`;

export const Selector = styled.div.attrs(() => ({ className: 'selector' }))`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 25px 0 0 0;
  gap: 24px;
  row-gap: 6px;
`;

// Mobile styles
export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 22px 64px 22px;
  padding: 70px 0;
  height: calc(100vh - 70px);
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transform: ${({ activateTransition }) =>
    activateTransition ? 'translateX(-110%)' : 'translateX(0%)'};
  transition: all 0.3s ease-in-out;

  .navigation-back {
    height: 16px;
    margin-right: 6px;
  }

  a {
    background-color: rgba(255, 255, 0, 0.5);
    box-shadow: 0px 2px 8px 0px rgba(255, 255, 0, 0.5);
    min-height: 50px;
    padding: 14px 19px;
    border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 18px;
  }

  a:hover {
    background-color: rgba(255, 255, 0, 0.8);
    box-shadow: 0px 2px 8px 0px rgba(255, 255, 0, 0.8);
  }

  @media (min-width: 834px) {
    display: none;
  }
`;

export const MobileTitle = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Culori.albastru};
  width: 100%;
  margin-top: 20px;
`;

export const MobileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 3px;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 100px;
`;

export const GlobalContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 62px;

  @media (max-width: 834px) {
    padding: 0;
    background-color: #fafbfb;
  }
`;
