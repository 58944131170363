import React from 'react';
import { SearchIcon, SearchInputContainer, SearchInputField } from './SearchInput.styles';
import Search from '../../../resurse/iconiteInterfata/search-icon.svg';
import { replaceDiactritics } from '../../../common/utils';

const SearchInput = ({ activateTransition, isListContentHidden, value, setValue, inputRef }) => {
  return (
    <SearchInputContainer activateTransition={activateTransition} hidden={isListContentHidden}>
      <SearchIcon src={Search} alt="search" />
      <SearchInputField
        type="text"
        placeholder="Caută"
        value={value}
        onChange={(e) => setValue(replaceDiactritics(e.target.value))}
        ref={inputRef}
      />
    </SearchInputContainer>
  );
};

export default SearchInput;
