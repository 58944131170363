import styled from 'styled-components';
import Culori from '../../constante/Culori';

export const ContainerAntet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 62px 0 62px;
  padding-bottom: 10px;
  z-index: 100;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: ${Culori.alb};

  .leftContainer {
    display: flex;
    align-items: center;
    gap: 20px
  }

  .sigla {
    cursor: pointer;
    height: 55px;

    @media (max-width: 834px) {
      height: 33px;
    }
  }

  .back-arrow {
    cursor: pointer;
    height: 50px;
    width: 50px;
    padding: 13px;
  }

  .navigation-back {
    height: 12px;
    margin-right: 6px;
  }

  a {
    font-size: 14px;
    background-color: rgba(255, 255, 0, 0.5);
    box-shadow: 0px 2px 8px 0px rgba(255, 255, 0, 0.5);
    border-radius: 4px;
    padding: 4px 8px;

    @media (max-width: 834px) {
      display: none;
    }
  }

  a:hover {
     background-color: rgba(255, 255, 0, 0.8);
    box-shadow: 0px 2px 8px 0px rgba(255, 255, 0, 0.8);
  }
  @media (max-width: 1000px) {
    a{
      font-size: 10px;
    }

    .navigation-back {
      height 12px;
      margin-right: 4px;
    }
  }

  .meniu {
    display: none;
    @media (max-width: 834px) {
      display: block;
    }
  }

  @media (max-width: 834px) {
    padding: 0 20px;
    margin: 0;
    background-color: ${Culori.alb};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    border-bottom: none;
    height: 60px;
    position: fixed;
    width: 100%;
  }
`;

export const BottomBreakLike = styled.div`
  widht: 100%;
  height: 1px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.065);
`;

export const ContainerTitluPagina = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity linear 0.2s;
  margin-left: -100px;

  @media (max-width: 834px) {
    margin-left: 0;
  }

  &.hidden {
    opacity: 0;
  }
`;

export const TitluPagina = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: ${Culori.albastru};

  @media (max-width: 834px) {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.44px;
  }
`;

export const SubtitluPagina = styled.h2`
  font-size: 16px;
  text-align: center;
  color: ${Culori.albastru};
  margin: 0;
  font-weight: 400;

  @media (max-width: 834px) {
    font-size: 12px;
    letter-spacing: 0.14px;
  }
`;

export const ContainerButoaneInterfata = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

// make this div wrap on third element
export const ContainerCarduriFacultati = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 40px 54px 0 54px;
`;

export const MeniuMobile = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  @media (max-width: 834px) {
    display: none;
  }
`;
