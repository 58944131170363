import styled from 'styled-components';
import Culori from '../../constante/Culori';

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 16px;
  padding-bottom: ${({ isIOS }) => (isIOS ? '20px' : '0')};
  height: ${({ isIOS }) => (isIOS ? '90px' : '70px')};
  background-color: ${Culori.alb};
  color: ${Culori.alb};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);

  @media (min-width: 834px) {
    display: none;
  }
`;

export const NavigationButtonContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.9);
    opacity: 0.7a;
  }
`;

export const NavigationButtonText = styled.span`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 400;
  color: ${Culori.albastru};
`;

export const NavigationButtonIcon = styled.img`
  height: 28px;
  width: 28px;
`;
