import React from 'react';
import { Icon, IconContainer, ItemContainer, ItemText } from './ModalOrareSalvate.styles';

const ModalItem = ({ icon, text, onClick }) => {
  return (
    <ItemContainer onClick={onClick}>
      <IconContainer>
        <Icon src={icon} alt="icon" />
      </IconContainer>
      <ItemText>{text}</ItemText>
    </ItemContainer>
  );
};

export default ModalItem;
