import {
  GET_FACULTATI_REQUEST,
  GET_FACULTATI_SUCCESS,
  GET_FACULTATI_FAILURE,
} from '../actions/facultatiActions';

const initialState = {
  loading: false,
  facultati: [],
  error: null,
};

function facultatiReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FACULTATI_REQUEST:
      return { ...state, loading: true };
    case GET_FACULTATI_SUCCESS:
      return {
        ...state,
        loading: false,
        facultati: action.facultati,
      };
    case GET_FACULTATI_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

export default facultatiReducer;
