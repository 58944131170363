import React from 'react';
import {
  ScheduleListContainer,
  CardContainer,
  WeekTitle,
  CardType,
  CardContent,
  CardTitle,
  CardTypeText,
  CardText,
} from './ScheduleList.styles';
import { WEEK_DAYS } from './constants';
import Culori from '../../constante/Culori';

function ScheduleList({ ore, oreData }) {
  const getCardColor = (type) => {
    switch (type) {
      case 'curs':
        return Culori.curs;
      case 'seminar':
        return Culori.seminar;
      case 'laborator':
        return Culori.laborator;
      default:
        return Culori.optional;
    }
  };

  return (
    <ScheduleListContainer>
      {ore.map((ora, index) => (
        <React.Fragment key={index}>
          {' '}
          {(index === 0 || ora.weekDay !== ore[index - 1].weekDay) && (
            <WeekTitle>{WEEK_DAYS[ora.weekDay - 1]}</WeekTitle>
          )}
          <CardContainer onClick={() => console.log(ora)}>
            <CardType bgColor={getCardColor(ora.typeLongName)}>
              <CardTypeText>{ora.typeLongName}</CardTypeText>
            </CardType>
            <CardContent>
              <CardTitle>
                {(ora.topicLongName || ora.otherInfo).replace(/^\w/, (c) => c.toUpperCase())}
              </CardTitle>

              <CardText>
                <span>Interval: </span>
                {`${ora.startHour / 60}:00 - ${
                  (parseInt(ora.startHour) + parseInt(ora.duration)) / 60
                }:00${
                  ora?.parity === 'i'
                    ? ', în săptămânile impare'
                    : ora?.parity === 'p'
                    ? ', în săptămânile pare'
                    : ora?.topicLongName
                    ? ora?.otherInfo && ', ' + ora?.otherInfo
                    : ''
                }
                ${
                  ora?.topicLongName && ora?.otherInfo.includes('Saptamana')
                    ? ', ' + ora?.otherInfo.toLowerCase()
                    : ''
                }
                `}
              </CardText>
              <CardText>
                <span>Sala: </span>
                {`${ora?.roomLongName} din corpul ${ora?.roomBuilding}`}
              </CardText>
              {window.location.pathname.includes('profesori') && oreData ? (
                oreData[ora.id][0].length > 0 && (
                  <CardText>
                    <span>Formații de studiu: </span>
                    {oreData[ora.id][0]}({oreData[ora.id][1]})
                  </CardText>
                )
              ) : (
                <CardText>
                  <span>Cadru didactic:</span>
                  {' ' +
                    ora?.positionShortName +
                    ' ' +
                    ora?.phdShortName +
                    ' ' +
                    ora?.otherTitle +
                    ' ' +
                    ora.teacherLastName +
                    ' ' +
                    ora.teacherFirstName}
                </CardText>
              )}
            </CardContent>
          </CardContainer>
        </React.Fragment>
      ))}
    </ScheduleListContainer>
  );
}

export default ScheduleList;
