// eslint-disable-next-line import/no-anonymous-default-export
export default {
  alb: '#FFFFFF',
  bordura: '#e0e0e0',
  fundal: '#ededed',
  rosu: 'red',
  verde: 'green',
  albastru: '#0D4DA1',
  albastru2: '#0D4DA1',
  seminar: '#FFF5D4',
  laborator: '#FFD5B9',
  curs: '#ADE0FF',
  proiect: '#AADCC6',
  optional: '#D3D3D3',
  borduraCelulaTabel: '#0D4DA1',
  placeholder: '#a3a3a3',
};
