import styled from 'styled-components';
import Culori from '../../constante/Culori';
import DimensiuniText from '../../constante/DimensiuniText';

export const ContainerLegenda = styled.table`
  width: 170px;
  color: ${Culori.text};
  background-color: ${Culori.bordura};
  text-align: center;
  margin: 0 0 20px auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px !important;

  @media (max-width: 834px) {
    display: none;
  }
`;

export const TitluLegenda = styled.td`
  background-color: #ededed;
  color: ${Culori.albastru};
  font-style: normal;
  font-weight: 400;
  font-size: ${DimensiuniText.text4};
  line-height: 20px;
  border: 1px solid ${Culori.bordura};
  border-bottom: 1px solid ${Culori.bordura};
  border-radius: 10px 10px 0px 0px !important;
  padding: 13.5px 0px !important;
`;

export const LinieLegenda = styled.tr``;

export const Culoare = styled.td`
  width: 70px;
  height: 37px;
  background-color: ${(props) => props.culoare} !important;
  border: 1px solid ${Culori.bordura};
  border-top: none;
  border-bottom-left-radius: ${(props) => props.bordura};
  padding: 5px 12.5px !important;
`;

export const SemnificatieCuloare = styled.td`
  border: 1px solid ${Culori.bordura};
  border-left: none;
  border-top: none;
  font-style: normal;
  font-weight: 400;
  font-size: ${DimensiuniText.text4};
  line-height: 20px;
  color: ${Culori.albastru};
  border-bottom-right-radius: ${(props) => props.bordura};
  text-align: left;
  background-color: ${Culori.alb};
  padding-left: 10px;
  padding: 5px 12.5px !important;
`;
