import styled from 'styled-components';
import Culori from '../../constante/Culori';

export const ScheduleListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const WeekTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${Culori.albastru};
`;

export const CardContainer = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);

  // &:hover {
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  //   cursor: pointer;
  // }

  // &:active {
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  //   opacity: 0.7;
  //   background-color: #f5f5f5;
  // }
`;

export const CardType = styled.div`
  width: 8%;
  height: 100%;
  background-color: ${(props) => props.bgColor};
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardTypeText = styled.h3`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${Culori.albastru};
  transform: rotate(-90deg);
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const CardContent = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
`;

export const CardTitle = styled.h3`
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 500;
  color: ${Culori.albastru};
`;

export const CardText = styled.h3`
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 400;
  color: ${Culori.albastru};

  span {
    font-weight: 600;
  }
`;
