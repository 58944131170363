import styled from 'styled-components';
import Culori from '../../../constante/Culori';

export const SwitchContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  background-color: ${Culori.alb};
  border-radius: 10px;
  width: 100%;
  max-width: 432px;
  height: 40px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

  display: ${({ activateTransition }) => (activateTransition ? 'flex' : 'none')};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transform: ${({ activateTransition }) =>
    activateTransition ? 'translateX(0)' : 'translateX(110%)'};
  transition: all 0.3s ease-in-out;

  @media (max-width: 834px) {
    max-width: 100%;
  }
`;

export const SwitchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;

  @media (min-width: 834px) {
    &:hover {
      cursor: pointer;
    }

    &:active {
      opacity: 0.5;
    }
`;

export const SwitchText = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  z-index: 2;
  outline: none;
  user-select: none;
  color: ${({ isSelected }) => (isSelected ? Culori.albastru : Culori.alb)};
  transition: all 0.2s linear;

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const SwitchCircle = styled.div`
  width: ${(props) => (props.optionsLength === 2 ? '50%' : '33.33%')};
  height: 40px;
  border-radius: 10px;
  background-color: ${Culori.albastru};
  transition: all 0.2s linear;
  transform: ${({ value }) => `translateX(${value})`};
  position: absolute;
  z-index: 1;
  outline: none;
`;
