const DimensiuniText = {
  text1: "2rem",
  text2: "22px",
  text3: "1.25rem",
  text4: "16px",
  text5: "0.875rem",
  text6: "0.75rem",
  text7: "0.625rem",
  text8: "0.5rem",
  text9: "0.375rem",
};

export default DimensiuniText;
