import styled from 'styled-components';
import Culori from '../../constante/Culori';
import DimensiuniText from '../../constante/DimensiuniText';

export const ButtonContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 2px solid ${Culori.bordura};
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  flex-grow: 1;
  background-color: transparent;
  text-decoration: none;
  outline: none;

  @media (min-width: 834px) {
    display: none;
  }
`;

export const Text = styled.p`
  font-size: ${DimensiuniText.text4};
  color: ${Culori.albastru};
  padding: 0 16px;
  margin: 0;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 2px solid ${Culori.bordura};
  overflow: hidden;
  color: ${Culori.albastru};
  overflow-y: scroll;
  font-size: ${DimensiuniText.text4};
  width: calc(100vw - 6px);
  top: 118px;
  left: 22px;
  height: calc(100vh - 180px);
  margin-left: -21px;
  z-index: 999;
  opacity: 0; // Start hidden
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
  `}

  .arrow-left {
    width: 20px;
    height: 20px;
  }
`;

export const Option = styled.button`
  cursor: pointer;
  padding: 10px 16px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
  font-size: ${DimensiuniText.text4};
  color: ${Culori.albastru};
  border-bottom: 1px solid ${Culori.bordura};

  &:hover {
    background-color: ${Culori.fundal};
  }

  &:active {
    background-color: ${Culori.bordura};
  }
`;

export const SelectedValueHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 38px;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  border-radius: 10px;
  border: 2px solid ${Culori.bordura};
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  height: 35px;
`;
