import {
  GET_ORAR_GRUPA_REQUEST,
  GET_ORAR_GRUPA_SUCCESS,
  GET_ORAR_GRUPA_FAILURE,
  GET_ORAR_PROFESOR_REQUEST,
  GET_ORAR_PROFESOR_SUCCESS,
  GET_ORAR_PROFESOR_FAILURE,
  GET_ORAR_SALA_REQUEST,
  GET_ORAR_SALA_SUCCESS,
  GET_ORAR_SALA_FAILURE,
  GET_CURRENT_WEEK,
} from '../actions/orarActions';

const initialState = {
  loading: false,
  orar: [],
  orarHtml: '',
  currentWeek: '',
  error: null,
};

function orarReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORAR_GRUPA_REQUEST:
      return { ...state, loading: true };
    case GET_ORAR_GRUPA_SUCCESS:
      return {
        ...state,
        loading: false,
        orar: action.orarSubgrupa,
      };
    case GET_ORAR_GRUPA_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_ORAR_PROFESOR_REQUEST:
      return { ...state, loading: true };
    case GET_ORAR_PROFESOR_SUCCESS:
      return {
        ...state,
        loading: false,
        orar: action.orarProfesor,
      };
    case GET_ORAR_PROFESOR_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_ORAR_SALA_REQUEST:
      return { ...state, loading: true };
    case GET_ORAR_SALA_SUCCESS:
      return {
        ...state,
        loading: false,
        orar: action.orarSala,
      };
    case GET_ORAR_SALA_FAILURE:
      return { ...state, loading: false, error: action.error };
    case 'SET_ORAR_HTML':
      return { ...state, orarHtml: action.orarHtml };
    case GET_CURRENT_WEEK:
      return {
        ...state,
        currentWeek: action.currentWeek,
      };
    default:
      return { ...state };
  }
}

export default orarReducer;
