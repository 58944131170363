import React, { useState, useRef, useEffect } from 'react';
import { SwitchButton, SwitchCircle, SwitchContainer, SwitchText } from './Switch.Styles';

function Switch({
  selectedOption,
  setSelectedOption,
  options = [],
  activateTransition,
  isListContentHidden,
}) {
  const [value, setValue] = useState(0);
  const switchRef = useRef(null);
  const [switchWidth, setSwitchWidth] = useState(0);

  const updateWidth = () => {
    if (switchRef.current) {
      setSwitchWidth(switchRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, [value, activateTransition, isListContentHidden]);

  useEffect(() => {
    setValue(selectedOption - 1);
  }, [selectedOption]);

  return (
    <SwitchContainer
      ref={switchRef}
      activateTransition={activateTransition}
      hidden={isListContentHidden}
      className="switch"
    >
      {options.map((option, index) => (
        <SwitchButton
          key={index}
          width={`${switchWidth / options.length}px`}
          onClick={() => {
            setValue(index);
            setSelectedOption(index + 1);
          }}
          isSelected={index === value}
        >
          <SwitchText isSelected={index !== value}>{option}</SwitchText>
        </SwitchButton>
      ))}
      <SwitchCircle
        value={
          options.length === 3
            ? value === 0
              ? '-100%'
              : value === 1
              ? '0'
              : '100%'
            : value === 0
            ? '-50%'
            : '50%'
        }
        optionsLength={options.length}
      />
    </SwitchContainer>
  );
}

export default Switch;
