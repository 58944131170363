import { GET_SALI_REQUEST, GET_SALI_SUCCESS, GET_SALI_FAILURE } from '../actions/saliActions';

const initialState = {
  loading: false,
  sali: [],
  error: null,
};

function saliReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SALI_REQUEST:
      return { ...state, loading: true };
    case GET_SALI_SUCCESS:
      return {
        ...state,
        loading: false,
        sali: action.sali,
      };
    case GET_SALI_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
}

export default saliReducer;
